import React, { useCallback, useRef, useState, useMemo, createElement } from 'react';
import styled, { useTheme } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'state';

import useServices from 'app/services';

import { signin } from 'actions/auth';

import { simpleEmailRegex } from 'app/utils/email-validation';

import EntryLayout from 'app/ui/components/layouts/entry';

import Typography from 'app/ui/components/atoms/typography';
import Button from 'app/ui/components/atoms/button';

import FormFieldSet from 'app/ui/components/molecules/form-field-set';

import ShowIcon from 'app/ui/components/atoms/icons/show';
import HideIcon from 'app/ui/components/atoms/icons/hide';

enum InputElementTypes {
  PASSWORD = 'password',
  TEXT = 'text',
};

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;

  ${({ theme: { spacing: { small } } }) => `
    margin-bottom: ${small};
  `}

  button {
    width: 100%;
    justify-content: center;
  }
`;

const TextFieldSetWrapper = styled.div`
  input {
    width: 100%;
  }
`;

const ButtonAreaWrapper = styled.div`
  ${({ theme: { spacing: { xxxsmall } } }) => `
    padding: ${xxxsmall} 0;
  `}
`;

const Underlined = styled.span`
  text-decoration: underline;
`;

const SigninPage = () => {
  const services = useServices();
  const dispatch = useDispatch();
  const intl = useIntl();

  const { state: locationState } = useLocation();
  const email = useRef<HTMLInputElement>(null);
  const password = useRef<HTMLInputElement>(null);

  const isReset = locationState?.reset;

  const defaultErrors = useMemo(
    () => ({
      email: null,
      password: null,
    }),
    [],
  );

  const [{ email: emailError, password: passwordError }, setErrors] = useState<{
    email: string | null;
    password: string | null;
  }>(defaultErrors);

  const [passwordFieldType, setPasswordFieldType] = useState<InputElementTypes>(InputElementTypes.PASSWORD);

  const onSubmit = useCallback(async () => {
    try {
      setErrors(defaultErrors);
      if (email.current && password.current) {
        if (!simpleEmailRegex.test(email.current.value)) {
          setErrors({
            email: intl.formatMessage({ id: 'signIn.errors.invalidEmail' }),
            password: null,
          });
          return;
        }
        const tokens = await services.auth.signin({
          username: email.current.value.trim(),
          password: password.current.value.trim(),
        });
        dispatch(signin(tokens));
      }
    } catch (err) {
      setErrors({
        email: intl.formatMessage({ id: 'signIn.errors.incorrectCredentials' }),
        password: '',
      });
    }
  }, [services, dispatch, defaultErrors]);

  const theme = useTheme();

  const onPasswordFieldTypeToggle = () => {
    setPasswordFieldType((prevType) => (
      prevType === InputElementTypes.PASSWORD ? InputElementTypes.TEXT : InputElementTypes.PASSWORD
    ));
  };

  const signInHeader = intl.formatMessage({ id: 'signIn.headers.default' });
  const passwordResetSuccess = intl.formatMessage({ id: 'signIn.headers.passwordResetSuccess' });
  const passwordResetPrompt = intl.formatMessage({ id: 'signIn.copy.passwordResetPrompt' });
  const emailLabel = intl.formatMessage({ id: 'signIn.labels.email' });
  const passwordLabel = intl.formatMessage({ id: 'signIn.labels.password' });

  const forgotPasswordLabel = intl.formatMessage({ id: 'signIn.actions.forgotPassword' });
  const signInLabel = intl.formatMessage({ id: 'signIn.actions.signIn' });

  const emailSubject = encodeURIComponent(intl.formatMessage({ id: 'signIn.emailSubject' }));

  return (
    <EntryLayout>
      <Typography variant="h4" color={theme.colors.white}>
        {isReset ? passwordResetSuccess : signInHeader}
      </Typography>
      {isReset && (
        <Typography variant="body2" color={theme.colors.lightGray}>
          {passwordResetPrompt}
        </Typography>
      )}
      <TextFieldSetWrapper>
        <FormFieldSet
          type="text"
          label={emailLabel}
          placeholder=""
          onChange={() => {}}
          inputRef={email}
          errorText={emailError}
        />
        <FormFieldSet
          type={passwordFieldType}
          label={passwordLabel}
          placeholder=""
          onChange={() => {}}
          inputRef={password}
          errorText={passwordError}
          onTypeToggle={onPasswordFieldTypeToggle}
          icon={passwordFieldType === InputElementTypes.PASSWORD ? createElement(HideIcon) : createElement(ShowIcon)}
        />
        <Typography variant="body2" color={theme.colors.knop}>
          <Link to="/forgot-password"><Underlined>{forgotPasswordLabel}</Underlined></Link>
        </Typography>
      </TextFieldSetWrapper>
      <ButtonAreaWrapper>
        <ButtonWrapper>
          <Button label={signInLabel} type="primary" onClick={onSubmit} />
        </ButtonWrapper>
        <Typography variant="body2" color={theme.colors.knop}>
          <FormattedMessage
            id="signIn.actions.needAssistance"
            values={{
              a: (chunks) => (
                <a href={`mailto:support+hyrox@fiit.tv?subject=${emailSubject}`}>
                  <Underlined>{chunks}</Underlined>
                </a>
              ),
            }}
          />
        </Typography>
      </ButtonAreaWrapper>
    </EntryLayout>
  );
};

export default SigninPage;
