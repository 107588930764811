import React from 'react';
import styled from 'styled-components';

import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import GenericPage from 'app/ui/components/layouts/generic-page';

import Error from 'app/ui/components/molecules/error';
import Loading from 'app/ui/components/molecules/loading';
import ResourceHeader from 'app/ui/components/molecules/resource-header';
import ResourceSectionDetails from 'app/ui/components/molecules/resource-section-details';
import Breadcrumb from 'app/ui/components/molecules/breadcrumb';

import { ResourcePage as RESOURCE } from 'app/pages/resource/resource.gql';
import { ResourcePageQuery, ResourcePageQueryVariables } from 'app/types/graphql';

const BreadcrumbWrapper = styled.div`
  display: none;

  ${({ theme: { media, spacing } }) =>
    `${media.medium(
      () => `
        display: flex;
        margin: ${spacing.extreme} 0 ${spacing.jumbo} 0;
    `,
    )}`};
`;

const ResourceSectionDetailsWrapper = styled.div`
  & > div:first-child {
    ${({ theme: { spacing } }) => `margin-top: ${spacing.extreme};`};
  }

  ${({ theme: { media } }) =>
    `${media.medium(
      () => `
        & > div:first-child {
          margin-top: 0;
        }
    `,
    )}`};
`;

type ResourcePageProps = {
  resourceId: string;
};

const ResourcePage: React.FC<ResourcePageProps> = ({ resourceId }: ResourcePageProps) => {
  const intl = useIntl();

  const { data, loading, error } = useQuery<ResourcePageQuery, ResourcePageQueryVariables>(RESOURCE, {
    variables: { id: resourceId },
  });

  if (loading) {
    return <Loading />;
  }

  if (error || !data || !data.resourceById) {
    return <Error />;
  }

  const { title, content, image, sections = [] } = data.resourceById;

  const breadcrumbLabel = intl.formatMessage({ id: 'resource.breadcrumb' });

  return (
    <GenericPage>
      <ResourceHeader title={title} content={content} imageUrl={image?.url || image?.signedUrl} />
      <BreadcrumbWrapper>
        <Breadcrumb content={[breadcrumbLabel, title]} />
      </BreadcrumbWrapper>
      <ResourceSectionDetailsWrapper>
        {(sections || []).map(({ title, content, images, videos, documents, links }, i) => {
          const imageUrl = (images || []).find(({ signedUrl }) => !!signedUrl)?.signedUrl;
          const video = (videos || []).find(({ signedUrl }) => !!signedUrl);
          const documentUrl = (documents || []).find(({ signedUrl }) => !!signedUrl)?.signedUrl;
          const linkUrl = links?.length ? links[0].url : null;
          return (
            <ResourceSectionDetails
              title={title}
              content={content}
              imageUrl={imageUrl || image?.url || image?.signedUrl}
              video={video}
              documentUrl={documentUrl}
              linkUrl={linkUrl}
              mediaFirst={!!(i % 2)}
            />
          );
        })}
      </ResourceSectionDetailsWrapper>
    </GenericPage>
  );
};

const Resource: React.FC = () => {
  const { id: resourceId } = useParams();
  if (!resourceId) {
    return <Error />;
  }

  return <ResourcePage resourceId={resourceId} />;
};
export default Resource;
