import React, { ReactNode } from 'react';
import styled, { useTheme } from 'styled-components';

import { Video } from 'app/types/graphql';
import { HandleSectionFocusChangeProps } from 'app/pages/workout';

import Typography from 'app/ui/components/atoms/typography';

import ChevronDownIcon from 'app/assets/chevron-down-icon.svg';
import ChevronUpIcon from 'app/assets/chevron-up-icon.svg';

type BaseContentData = {
  title: string,
  content?: string | ReactNode | null,
  extraContent?: string | null,
  additionalInfo: string,
  id: string,
};

export type ContentItemData = BaseContentData & {
  video?: Video | null,
};

export type GroupContentItemData = BaseContentData & {
  sectionExercises: ContentItemData[],
  showTitle: boolean,
};

export type ContentData = BaseContentData & {
  titleExtraContent: ReactNode,
  contentItems?: ReactNode,
  notes?: string | null,
};

export type ContentItemsAreaProps = {
  items: GroupContentItemData[],
  contentItemFocused: string | null,
  handleContentItemClick: (prop: HandleSectionFocusChangeProps) => void,
}

const Title = styled(Typography)`
  margin: ${({ theme: { spacing } }) => `${spacing.medium} 0 ${spacing.xsmall} 0`};
`;

const ExerciseTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  cursor: pointer;
  padding: 0.25rem 0 0.25rem 1rem;
`;

const ExerciseItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: -1px;

  ${({ theme }) => `
    border-top: 1px solid ${theme.colors.devilsGray};
    border-bottom: 1px solid ${theme.colors.devilsGray};
  `}
`;

const Content = styled.div<{$isOpen: boolean}>`
  ${({ $isOpen }) => `display: ${$isOpen ? 'flex' : 'none'}`};
  flex-direction: column;
  padding: 1rem 0 1rem 1rem;
`;

const ContentItemArea = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const ContentItem = styled.div`
  width: 100%;
`;

const TitleText = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
`;

const Description = styled(Typography)`
  li {
    margin-left: 20px;
  }
`;

const GroupDescription = styled.div`
  margin-bottom: ${({ theme: { spacing } }) => `${spacing.xsmall}`};
`;

const ContentItemAreasWrapper: React.FC<ContentItemsAreaProps> = ({
  items,
  contentItemFocused,
  handleContentItemClick,
}) => {
  const theme = useTheme();

  if (!items.length) {
    return <></>;
  }

  return (
    <>
      {items.map(({ id: groupId, title, content, showTitle, sectionExercises }) => {
        return (
          <ContentItemArea key={`group-${groupId}`}>
            <ContentItem>
              {showTitle && (
                <>
                  <Title variant="body2" color={theme.colors.paragraphGray}>{title}</Title>
                  {content && (
                    <GroupDescription>
                      <Typography variant="caption" color={theme.colors.paragraphGray}>
                        {content}
                      </Typography>
                    </GroupDescription>
                  )}
                </>
              )}
              <div>
                {sectionExercises?.map(({ title, additionalInfo, content, extraContent, id }) => {
                  const isOpen = contentItemFocused === id;
                  return (
                    <ExerciseItem key={`${groupId}-${id}`}>
                      <ExerciseTitle
                        onClick={isOpen ? () => handleContentItemClick({ exerciseKey: null }) : () => handleContentItemClick({ exerciseKey: id })}
                      >
                        {
                          additionalInfo && (
                            <Typography variant="body2" color={isOpen ? theme.colors.focoltone : theme.colors.paragraphGray}>
                              {additionalInfo}
                            </Typography>
                          )
                        }
                        <TitleText variant="body2" color={isOpen ? theme.colors.focoltone : theme.colors.paragraphGray}>{title}</TitleText>
                        {
                          isOpen ? (
                            <img src={ChevronUpIcon} alt="Chevron Up Icon" />
                          ) : (
                            <img src={ChevronDownIcon} alt="Chevron Down Icon" />
                          )
                        }
                      </ExerciseTitle>
                      <Content $isOpen={isOpen}>
                        {content && (
                          typeof content === 'string' ? (
                            <Description variant="caption" color={theme.colors.paragraphGray}>
                              <span dangerouslySetInnerHTML={{ __html: content }} />
                            </Description>
                          ) : (
                            <Description variant="caption" color={theme.colors.paragraphGray}>
                              {content}
                            </Description>
                          )
                        )}
                        <Typography variant="caption" color={theme.colors.paragraphGray}>{extraContent}</Typography>
                      </Content>
                    </ExerciseItem>
                  )
                })}
              </div>
            </ContentItem>
          </ContentItemArea>
        )
      }
      )
    }
    </>
  );
};

export default ContentItemAreasWrapper;
