/* eslint-disable no-process-env */

export const num = (val: any) => (val == null ? val : parseInt(val, 10));
export const bool = (val: any) => (val == null ? val : val.toString() === 'true');

export default {
  REACT_APP_ENVIRONMENT: {
    value: process.env.NODE_ENV,
    defaultValue: 'development',
  },
  REACT_APP_API_ENVIRONMENT: {
    value: process.env.REACT_APP_API_ENVIRONMENT,
    defaultValue: 'staging',
  },
  REACT_APP_AUTH_CLIENT_ID: {
    value: process.env.REACT_APP_AUTH_CLIENT_ID,
  },
  REACT_APP_AUTH_CLIENT_SECRET: {
    value: process.env.REACT_APP_AUTH_CLIENT_SECRET,
  },
  REACT_APP_AUTH_URL: {
    value: process.env.REACT_APP_AUTH_URL,
  },
  REACT_APP_HYROX_API_URL: {
    value: process.env.REACT_APP_HYROX_API_URL,
  },
  REACT_APP_PASSWORD_URL: {
    value: process.env.REACT_APP_PASSWORD_URL,
  },
  REACT_APP_FEEDBACK_URL: {
    value: process.env.REACT_APP_FEEDBACK_URL,
  },
};
