import React, { useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import HyroxLogo from 'app/ui/components/atoms/hyrox-logo';

import ProfileIcon from 'app/assets/profile-icon.svg';
import { routes } from 'app/routes/constants';
import { Link } from 'react-router-dom';

import LanguagePicker from 'app/ui/components/molecules/language-picker/wrapper';

type MenuItem = {
  path: string,
  localeKey: string,
};

const NavbarContainer = styled.nav`
  z-index: 99;
  position: sticky;
  top: 0;
  width: 100%;

  ${({ theme: { colors, typography, spacing } }) => `
    font-size:  ${typography.small};
    background: ${colors.black};
    color: ${colors.white};
    margin-bottom: ${spacing.medium};
  `}
`;

const NavbarContentWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  height: 4.75rem;
  gap: 2.5rem;
  margin: 0 auto;

  ${({ theme: { media } }) => `
    ${media.small(
      () => `
        padding: 0 2.1875rem;
      `,
    )}
    ${media.large(
      () => `
        padding: 0 2.1875rem;
        max-width: 67.5rem;
      `,
    )}
  `}
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 7.625rem;
  }
`;

const Menu = styled.ul<{ $isMenuOpen: boolean }>`
  ${({ theme: { media, colors }, $isMenuOpen }) => `
  height: fit-content;
  list-style: none;
  text-decoration: none;
  gap: 1.875rem;
  color: ${colors.white};
  background: ${colors.black};
  position: absolute;
  display: ${$isMenuOpen ? 'flex' : 'none'};
  top: 4.5rem;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  flex-direction: column;
  text-align: center;
  padding-top: 0.5rem;

  ${media.large(
    () => `
      position: relative;
      width: fit-content;
      height: auto;
      top: unset;
      left: unset;
      right: unset;
      bottom: unset;
      display: flex;
      flex-direction: row;
      align-items: baseline;
      margin-left: auto;
      padding-top: unset;
    `,
  )}

  `}
`;

const NavMenuItem = styled.li`
  cursor: pointer;
  border-bottom: 0.0625rem solid transparent;
  padding-bottom: ${({ theme: { spacing } }) => spacing.small};

  a {
    text-decoration: none;
    color: inherit;
  }
  ${({ theme: { colors, media } }) => `
    ${media.large(
      () => `
      transition: border-bottom 0.3s ease-in-out;
      padding-bottom: 0;
      &:hover {
        border-bottom: 0.0625rem solid ${colors.focoltone};
      }`,
    )}
  `}
`;

const ProfileButton = styled(Link)`
  background: transparent;
  border: none;
  cursor: pointer;
  border-radius: 1rem;
  margin-left: auto;
  ${({ theme: { media } }) => `
    ${media.large(
      () => `
        margin-left: 0;
      `,
    )}`
  }
`;

// only for mobile
const BurgerMenu = styled.div`
  display: block;
  margin-right: auto;

  ${({ theme: { media } }) => `
  ${media.large(
    () => `
    display: none;
    cursor: pointer;`,
  )}

  
`}
`;

const ProfileIconWrapper = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.15rem;
  align-items: baseline;

  img {
    width: 100%;
    height: 100%;
  }
`;

// only for mobile
const BurgerLine = styled.span`
  display: block;
  width: 1.5625rem;
  height: 0.1875rem;
  margin: 0.3125rem 0;
  transition: all 0.3s ease-in-out;
  ${({ theme: { colors } }) => `
    background: ${colors.white};
  `}
`;

const menuItems: MenuItem[] = [
  { path: '/', localeKey: 'home' },
  { path: routes.WORKOUTS, localeKey: 'library' },
  { path: routes.RESOURCES, localeKey: 'resources' },
  { path: routes.FAQ, localeKey: 'support' },
];

const profilePath = routes.PROFILE;

const Navbar: React.FC = () => {
  const intl = useIntl();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <NavbarContainer>
      <NavbarContentWrapper>
        <BurgerMenu onClick={toggleMenu}>
          <BurgerLine />
          <BurgerLine />
          <BurgerLine />
        </BurgerMenu>
        <Link to="/">
          <LogoWrapper>
            <HyroxLogo />
          </LogoWrapper>
        </Link>
        <Menu $isMenuOpen={isMenuOpen}>
          {menuItems.map(({ path, localeKey }) => (
            <NavMenuItem key={localeKey}>
              <Link to={path}>{intl.formatMessage({ id: `nav.${localeKey}` })}</Link>
            </NavMenuItem>
          ))}
        </Menu>
        <LanguagePicker />
        <ProfileButton to={profilePath}>
          <ProfileIconWrapper>
            <img src={ProfileIcon} alt="Link to profile page" />
          </ProfileIconWrapper>
        </ProfileButton>
      </NavbarContentWrapper>
    </NavbarContainer>
  );
};

export default Navbar;
