import React, { useMemo, useCallback } from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';

import { IntlProvider } from 'react-intl';
import { locales } from 'app/locales';

import createApolloClient from 'app/utils/apollo/client';

import { theme } from 'app/providers/theme';
import GlobalStyle from 'app/global-styles';

import useConfig, { ConfigProvider } from 'app/config';
import useServices, { ServicesProvider } from 'app/services';

import { TokenPayload } from 'app/services/auth';

import { StateProvider, useAppState, useDispatch } from 'state';
import { AppRoutes } from 'app/routes/app-routes';
import { refreshTokens, signout } from 'actions/auth';

import { AnalyticsProvider } from 'app/utils/analytics/provider';

const PageWrapper = () => {
  const { config } = useConfig();
  const services = useServices();
  const dispatch = useDispatch();

  const onTokenRefresh = useCallback((payload: TokenPayload) => dispatch(refreshTokens(payload)), [dispatch]);

  const onTokenRefreshError = useCallback(() => dispatch(signout()), [dispatch]);

  const { client } = useMemo(
    () =>
      createApolloClient({
        uri: config.REACT_APP_HYROX_API_URL,
        authService: services.auth,
        onTokenRefresh,
        onTokenRefreshError,
      }),
    [config.REACT_APP_HYROX_API_URL],
  );

  return (
    <ApolloProvider client={client}>
      <AppRoutes />
    </ApolloProvider>
  )
}

const AppWithConfig = () => {
  const { config } = useConfig();

  return (
    <ThemeProvider theme={theme}>
      <AnalyticsProvider config={config}>
        <ServicesProvider config={config}>
          <GlobalStyle />
            <StateProvider>
              <AppWithLocale />
            </StateProvider>
        </ServicesProvider>
      </AnalyticsProvider>
    </ThemeProvider>
  );
};

const AppWithLocale = () => {
  const languageCode = useAppState(({ language }) => language?.preferredLanguage?.code) || 'en';

  return (
    <IntlProvider defaultLocale='en' locale={languageCode} messages={locales[languageCode]}>
      <Router>
        <PageWrapper />
      </Router>
    </IntlProvider>
  );
}

const App = () => (
  <ConfigProvider>
    <AppWithConfig />
  </ConfigProvider>
);

const root = createRoot(document.getElementById('root') || document.createElement('div'));
root.render(<App />);
