import cookies from 'js-cookie';

import { LanguageActionTypes } from 'actions/language';
import createReducer from 'utils/create-reducer';
import { Language } from 'app/types/graphql';

export type LanguageState = {
  preferredLanguage: Language | null,
};

const LANGUAGE_COOKIE_NAME = 'preferred_language_code';

const DEFAULT_LANGUAGE: Language = { code: 'en', name: 'English' };

const getPersistedLanguage = (): string | null => (
  cookies.get(LANGUAGE_COOKIE_NAME) || null
);

export const initialLanguageState: LanguageState = {
  preferredLanguage: getPersistedLanguage() ? { code: getPersistedLanguage() } as Language : DEFAULT_LANGUAGE,
};

const setLanguageState = (language: string | null) => {
  if (language) {
    cookies.set(LANGUAGE_COOKIE_NAME, language, { domain: window.location.hostname });
  } else {
    cookies.remove(LANGUAGE_COOKIE_NAME);
  }
};

const handlers = {
  [LanguageActionTypes.SET_LANGUAGE]: (state: LanguageState, payload: Language): LanguageState => {
    const newState = {
      ...state,
      preferredLanguage: payload,
    };
    setLanguageState(newState.preferredLanguage?.code);
    return newState;
  },
  [LanguageActionTypes.RESET_LANGUAGE]: (): LanguageState => {
    setLanguageState(null);
    return initialLanguageState;
  },
};

export const languageReducer = createReducer<LanguageState>(handlers);
