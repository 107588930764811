import React, { ReactNode, createContext, useContext } from 'react';
import configEntries from 'app/config/entries';

export type Config = {
  REACT_APP_ENVIRONMENT: string,
  REACT_APP_API_ENVIRONMENT: string,
  REACT_APP_AUTH_CLIENT_ID: string,
  REACT_APP_AUTH_CLIENT_SECRET: string,
  REACT_APP_AUTH_URL: string,
  REACT_APP_HYROX_API_URL: string,
  REACT_APP_PASSWORD_URL: string,
  REACT_APP_FEEDBACK_URL: string,
};

type RawConfigValue = {
  value: any,
  defaultValue?: any,
  format?: (value: any) => any,
};

const configContext = {
  config: Object
    .entries<RawConfigValue>(configEntries)
    .reduce((formattedConfig, [key, { value, format, defaultValue }]) => ({
      ...formattedConfig,
      [key]: format ? format(value || defaultValue) : (value || defaultValue),
    }), {}) as Config,
};

export const { config } = configContext;

const context = createContext(configContext);

const { Provider } = context;

export const ConfigProvider = ({ children }: { children: ReactNode }) => (
  <Provider value={configContext}>
    { children }
  </Provider>
);

export default () => useContext(context);
