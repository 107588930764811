import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';

import GenericPage from 'app/ui/components/layouts/generic-page';

import PageTitle from 'app/ui/components/molecules/page-title';
import Error from 'app/ui/components/molecules/error';
import Loading from 'app/ui/components/molecules/loading';

import Typography from 'app/ui/components/atoms/typography';
import ResourceCard from 'app/ui/components/atoms/resource-card';

import { Resources as RESOURCES } from 'app/pages/resources/resources.gql';

import { ResourcesQuery, ResourcesQueryVariables } from 'app/types/graphql';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  gap: 2.5rem;
`;

const ResourceCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 4rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`;

const ResourcesPage: React.FC = () => {
  const theme = useTheme();
  const intl = useIntl();

  const { data, loading, error } = useQuery<ResourcesQuery, ResourcesQueryVariables>(RESOURCES);

  if (loading) {
    return <Loading />;
  }

  if (error || !data || !data.allResources) {
    return <Error />;
  }

  const resourcesHeader = intl.formatMessage({ id: 'resources.header' });
  const resourcesCopy = intl.formatMessage({ id: 'resources.copy' });

  return (
    <GenericPage>
      <Container>
        <TitleWrapper>
          <PageTitle>
            <Typography variant="h3" color={theme.colors.white}>
              {resourcesHeader}
            </Typography>
          </PageTitle>
          <Typography variant="body2" color={theme.colors.lightGray}>
            {resourcesCopy}
          </Typography>
        </TitleWrapper>
        <ResourceCardWrapper>
          {data.allResources.map(({ title, id, image, content }, i) => (
            <ResourceCard
              title={title}
              paragraph={content}
              imagePosition={i % 2 ? 'left' : 'right'}
              key={title}
              imageSrcSet={
                image?.signedUrl || 'https://hyrox.com/wp-content/uploads/2022/06/Man-celebrating-his-HYROX-race.jpg'
              }
              to={`/resource/${id}`}
            />
          ))}
        </ResourceCardWrapper>
      </Container>
    </GenericPage>
  );
};
export default ResourcesPage;
