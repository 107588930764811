import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useAppState } from 'state';

import {
  Equipment,
  SaveLessonByIdMutation,
  SaveLessonByIdMutationVariables,
  UnsaveLessonByIdMutation,
  UnsaveLessonByIdMutationVariables,
} from 'app/types/graphql';

import Typography from 'app/ui/components/atoms/typography';
import DotDivider from 'app/ui/components/atoms/dot-divider';

import FilledBookmark from 'app/ui/components/atoms/icons/bookmark/filled';
import EmptyBookmark from 'app/ui/components/atoms/icons/bookmark/empty';
import { getEquipmentIcons } from 'app/ui/components/atoms/class-card/equipment-icons';

import {
  SaveLessonByIdClassCard as SAVE_LESSON,
  UnsaveLessonByIdClassCard as UNSAVE_LESSON,
} from 'app/ui/components/atoms/class-card/mutations.gql';
import Tick from 'app/ui/components/atoms/icons/tick';

export type Props = {
  lessonId: string,
  srcSet: string,
  title: string,
  to: string,
  intensity: string,
  equipment?: Equipment[] | null,
  isSaved: boolean,
  isCompleted: boolean,
};

const MainContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 0.25rem;
  justify-content: space-between;
  width: 20rem;
  background: ${({theme}) => theme.colors.veryGray};
  &:hover {
    background: ${({theme}) => theme.colors.focoltone};
    color: ${({theme}) => theme.colors.black};
  }
`;

const ImageContainer = styled.div<{ srcSet: string, isHover: boolean }>`
  aspect-ratio: calc(420 / 260);
  background-image: url(${({ srcSet }) => srcSet});
  background-size: cover;
  background-position: center;
  ${({ isHover }) => isHover && `
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      background:
        linear-gradient(180deg, rgba(0, 0, 0, 0) 63.23%, rgba(0, 0, 0, 0.3) 100%),
        linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    }
  `}
`;

const Title = styled(Typography)`
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.5;
  color: inherit;
`;

const RPE = styled(Typography)`
  color: inherit;
`;

const ContentContainer = styled.div`
  padding: ${({ theme: { spacing } }) => spacing.medium};
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;

  h5 {
    text-transform: uppercase;
  }
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const ExtraContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  flex-wrap: wrap;
`;

const EqIcons = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme: { spacing: { xsmall } } }) => `${xsmall}`};
`;

const BookmarkWrapper = styled.div`
  ${({ theme: { spacing: { jumbo, xsmall } } }) => `
    width: ${jumbo};
    height: ${jumbo};
    margin-left: ${xsmall};

    svg {
      width: ${jumbo};
      height: ${jumbo};
    }
  `}
`;

const Label = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
  border-radius: 1rem;
  background: white;
  padding: 0.25rem 0.5rem;
  display: flex;
  gap: 0.25rem;
  color: black;
  opacity: 0.7;
  align-items: center;
`;

const ClassCardComponent: React.FC<Props> = ({
  lessonId,
  srcSet,
  title,
  to,
  intensity,
  equipment = [],
  isSaved,
  isCompleted,
}) => {
  const theme = useTheme();
  const userId = useAppState(({ auth }) => auth.userId);

  const [isHovered, setIsHovered] = useState(false);
  const [isLessonSaved, setIsLessonSaved] = useState(isSaved);

  const iconColor = isHovered ? theme.colors.black : theme.colors.white;
  const filledBookmarkColor = isHovered ? theme.colors.black : theme.colors.focoltone;

  const [saveLesson, { data: saveData, reset: resetSave }] = useMutation<
    SaveLessonByIdMutation,
    SaveLessonByIdMutationVariables
  >(SAVE_LESSON, {
    onError: () => {
      setIsLessonSaved(false);
      resetSave();
    }
  });

  const [unsaveLesson, { data: unsaveData, reset: resetUnsave }] = useMutation<
    UnsaveLessonByIdMutation,
    UnsaveLessonByIdMutationVariables
  >(UNSAVE_LESSON);

  useEffect(() => {
    if (saveData) {
      setIsLessonSaved(saveData.saveLessonById.isSaved);
      resetSave();
    }

    if (unsaveData) {
      setIsLessonSaved(unsaveData.unsaveLessonById.isSaved);
      resetUnsave();
    }
  }, [
    saveData,
    unsaveData,
    setIsLessonSaved,
  ]);

  const handleSaveLesson = async () => {
    userId && saveLesson({ variables: { id: lessonId, input: { user: { id: userId } } } })
  };

  const handleUnsaveLesson = async () => (
    userId && unsaveLesson({ variables: { id: lessonId, input: { user: { id: userId } } } })
  );

  return (
    <MainContainer
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onTouchStart={() => setIsHovered(true)}
      onTouchEnd={() => setIsHovered(false)}
    >
      <Link to={to}>
        <ImageContainer srcSet={srcSet} isHover={isHovered}>
          {isCompleted ? (
            <Label>
              <Tick color={theme.colors.black} />
            </Label>
          ): null}
        </ImageContainer>
      </Link>
      <ContentContainer>
        <TextContent>
          <Link to={to}>
            <Title variant='h5'>{title}</Title>
          </Link>
          <ExtraContent>
            <RPE variant='body2'>{intensity}</RPE>
            {
              equipment && (
                <>
                  <DotDivider color={iconColor} />
                  <EqIcons>
                    {getEquipmentIcons(equipment, iconColor)}
                  </EqIcons>
                </>
              )
            }
          </ExtraContent>
        </TextContent>
        {isLessonSaved ? (
          <BookmarkWrapper onClick={handleUnsaveLesson}>
            <FilledBookmark color={filledBookmarkColor} />
          </BookmarkWrapper>
        ) : (
          <BookmarkWrapper onClick={handleSaveLesson}>
            <EmptyBookmark color={iconColor} />
          </BookmarkWrapper>
        )}
      </ContentContainer>
    </MainContainer>
  );
};

export default ClassCardComponent;
