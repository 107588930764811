import React, { Dispatch, SetStateAction } from 'react';
import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';

import { lessonToWorkout } from 'app/pages/workouts';
import { WorkoutListWrapperQuery, WorkoutListWrapperQueryVariables } from 'app/types/graphql';
import { WorkoutListWrapper as WORKOUTS } from 'app/ui/components/molecules/workout-list-wrapper/workouts.gql';

import WorkoutList from 'app/ui/components/molecules/workout-list';
import WorkoutFilter from 'app/ui/components/molecules/workout-filter';

import { Pills } from 'app/ui/components/molecules/pill-field-set';
import { AVAILABLE_DURATION_DATA, DurationData } from 'app/ui/components/molecules/workout-list-controller';

import { minutesToSeconds } from 'app/utils/duration-handler';

type SelectedFilters = {
  concept: string | null,
  equipment: string[],
  intensity: number | null,
  duration: DurationData['id'] | null,
};

type SelectedFiltersHandlers = {
  concept: (value: string) => void,
  equipment: (value: string) => void,
  duration: (value: string) => void,
  intensity: Dispatch<SetStateAction<number | null>>,
}

type FilterPills = {
  concept: Pills,
  equipment: Pills,
  duration: Pills,
}

type ManageMoreFilters = {
  showButton: boolean,
  showSidebar: boolean,
  setSidebar: (value: boolean) => void,
  reset: () => void,
};

type Props = {
  selectedFilters: SelectedFilters,
  selectedFiltersHandlers: SelectedFiltersHandlers,
  filterPills: FilterPills,
  manageMoreFilters: ManageMoreFilters,
}

const getWorkoutDurationRange = (selectedDuration: DurationData['id']) => {
  const DEFAULT_RANGE = { min: 0, max: minutesToSeconds(120) };

  const durationRange = AVAILABLE_DURATION_DATA.find((element) => element.id === selectedDuration);

  if (durationRange) {
    return {
      ...(durationRange.min ? { min: minutesToSeconds(durationRange.min) } : {}),
      ...(durationRange.max ? { max: minutesToSeconds(durationRange.max) } : {}),
    };
  }
  return DEFAULT_RANGE;
};

const WorkoutListWrapper: React.FC<Props> = ({
  selectedFilters,
  selectedFiltersHandlers,
  filterPills,
  manageMoreFilters,
}) => {
  const intl = useIntl();

  const queryVariables: WorkoutListWrapperQueryVariables = {
    lessonsCondition: {
      ...(selectedFilters.concept && selectedFilters.concept !== 'all-concept'
        ? { concept: { id: selectedFilters.concept } }
        : {}
      ),
      ...(selectedFilters.intensity 
        ? { intensity: selectedFilters.intensity }
        : {}
      ),
      ...(selectedFilters.equipment.length > 0 && !selectedFilters.equipment.includes('all-equipment')
        ? { equipment: selectedFilters.equipment.map((id) => ({ id: Number(id) })) }
        : {}
      ),
      ...(selectedFilters.duration
        ? { durationRange: getWorkoutDurationRange(selectedFilters.duration) }
        : {}
      ),
    },
  };

  const { data, loading } = useQuery<WorkoutListWrapperQuery, WorkoutListWrapperQueryVariables>(WORKOUTS, {
    variables: queryVariables,
  });

  const workouts = data?.lessons?.map((lesson: any) => lessonToWorkout(lesson)) || [];

  const title = intl.formatMessage({ id: 'workoutLibrary.header' });

  return (
    <>
      <WorkoutList
        title={title}
        workouts={workouts}
        isLoading={loading}
        handleConceptChange={selectedFiltersHandlers.concept}
        conceptFilters={filterPills.concept}
        setShowMoreFilters={manageMoreFilters.setSidebar}
        allowMoreFilters={manageMoreFilters.showButton}
        largeTitle
      />
      {manageMoreFilters.showSidebar && (
        <WorkoutFilter
          equipmentFilters={filterPills.equipment}
          durationFilters={filterPills.duration}
          conceptFilters={filterPills.concept}
          intensityValue={selectedFilters.intensity}
          handleEquipmentChange={selectedFiltersHandlers.equipment}
          handleIntensityChange={selectedFiltersHandlers.intensity}
          handleDurationChange={selectedFiltersHandlers.duration}
          handleConceptChange={selectedFiltersHandlers.concept}
          setShowMoreFilters={manageMoreFilters.setSidebar}
          resetFilters={manageMoreFilters.reset}
        />
      )}
    </>
  );
};

export default WorkoutListWrapper;
