import React, { useState, useCallback, ReactNode } from 'react';
import styled, { useTheme } from 'styled-components';
import { useIntl } from 'react-intl';

import Typography from 'app/ui/components/atoms/typography';
import ExpandableText from 'app/ui/components/molecules/expandable-text';

import { MinusIcon, PlusIcon } from 'app/ui/components/atoms/plus-minus';

export interface AccordionItemProps {
  title: string,
  titleExtraContent?: ReactNode,
  content?: string | ReactNode | null,
  id: string,
  additionalInfo?: string,
  contentItems?: ReactNode,
  notes?: string | null,
}
export interface AccordionItemComponentProps {
  title: string,
  titleExtraContent?: ReactNode,
  content?: string | ReactNode | null,
  id: string,
  isOpen: boolean,
  additionalInfo?: string,
  onClick?: (key: string | null) => void,
  mapPropToChildren?: any[],
  contentItems?: ReactNode,
  notes?: string | null,
}

export type AccordionProps = {
  data: AccordionItemProps[],
  className?: string,
  onClickEmit?: (key: string | null) => void,
  initialSelectedKey?: string,
};

const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AccordionItem = styled.div`
  margin: 0.5rem 0;
  ${({ theme: { colors } }) => `
    background: ${colors.black};
    color: ${colors.white};
  `}
`;

type AccordionTitleTextColors = {
  focused?: string,
  notFocused?: string,
};

export const AccordionTitle = styled.div<{ $isOpen: boolean, index?: number, $titleColors?: AccordionTitleTextColors }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ $isOpen, theme: { colors } }) => ($isOpen ? colors.focoltone : colors.veryGray)};
  cursor: pointer;
  padding: ${({ theme: { spacing } }) => `${spacing.xxsmall} ${spacing.small}`};

  h6 {
    color: ${({ $isOpen, theme: { colors }, $titleColors }) => ($isOpen ?
      $titleColors?.focused || colors.black :
      $titleColors?.notFocused || colors.white
    )};
  }
`;

export const AdditionalInfoSection = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1rem;
  flex-shrink: 0;
  padding-left: 0.5rem;

  ${({ theme: { media } }) => `${media.medium(() => `
      display: flex;
    `)}
  `};
`;

export const AccordionContent = styled.div<{ $isOpen: boolean }>`
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  padding: ${({ theme: { spacing } }) => `${spacing.medium} 0`};
`;

export const AccordionDescription = styled.div`
  margin-bottom: ${({ theme: { spacing } }) => `${spacing.medium}`};
  display: flex;
  flex-direction: column;
`;

const Icons = styled.div`
  ${({ theme: { spacing: { jumbo } } }) => `
    width: ${jumbo};
    height: ${jumbo};

    svg {
      width: ${jumbo};
      height: ${jumbo};
    }
  `}
`;

const TitleWithContent = styled.div<{ $isOpen: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  gap: ${({ theme: { spacing } }) => `${spacing.medium}`};

  ${({ $isOpen }) => (!$isOpen && `svg {
      filter: invert(100%);
    }`
  )};

  ${({ theme: { media } }) => `${media.medium(() => `
      max-width: 80%;
    `)}
  `};
`;

const ExpandableTextWrapper = styled.div`
  margin-top: ${({ theme }) => `${theme.spacing.medium}`};
`;

const EndContentWithIcons = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme: { spacing } }) => `${spacing.xsmall}`};
  margin-left: ${({ theme: { spacing } }) => `${spacing.xsmall}`};
`;

const AccordionItemComponent: React.FC<AccordionItemComponentProps> = ({
  title,
  titleExtraContent,
  content,
  additionalInfo,
  id,
  isOpen,
  onClick,
  contentItems,
  notes,
}: AccordionItemComponentProps) => {
  const theme = useTheme();
  const intl = useIntl();

  const toggleAccordion = () => {
    // Opening
    if (onClick && !isOpen) {
      onClick(id);
    }
    // Closing
    if (onClick && isOpen) {
      onClick(null);
    }
  };

  const notesLabel = intl.formatMessage({ id: 'accordions.labels.notes' });

  return (
    <AccordionItem id={id}>
      <AccordionTitle $isOpen={isOpen} onClick={() => toggleAccordion()}>
        <TitleWithContent $isOpen={isOpen}>
          <Typography variant="h6">{title}</Typography>
          {titleExtraContent}
        </TitleWithContent>
        <EndContentWithIcons>
          <AdditionalInfoSection>
            <Typography variant="h6" color={isOpen ? theme.colors.black : theme.colors.lightGray}>
              {additionalInfo}
            </Typography>
          </AdditionalInfoSection>
          <Icons>{isOpen ? <MinusIcon /> : <PlusIcon />}</Icons>
        </EndContentWithIcons>
      </AccordionTitle>
      <AccordionContent $isOpen={isOpen}>
        {content && (
          <AccordionDescription>
            <Typography variant="body2" color={theme.colors.paragraphGray}>{content}</Typography>
          </AccordionDescription>
        )}
        {contentItems}
        {notes && (
          <ExpandableTextWrapper>
            <ExpandableText text={`${notesLabel}: ${notes}`} />
          </ExpandableTextWrapper>
        )}
      </AccordionContent>
    </AccordionItem>
  );
};

const Accordion: React.FC<AccordionProps> = ({
  data,
  className,
  onClickEmit,
  initialSelectedKey,
}) => {
  const [focusedKey, setFocusedKey] = useState<string | null>(initialSelectedKey || null);

  const onClick = useCallback((id: string | null) => {
    if (onClickEmit) {
      onClickEmit(id);
    }
    setFocusedKey(id);
  }, []);

  return (
    <AccordionSection className={className}>
      {data.map((item, index) => (
        <AccordionItemComponent
          key={index}
          title={item.title}
          titleExtraContent={item.titleExtraContent}
          content={item.content}
          contentItems={item.contentItems}
          additionalInfo={item.additionalInfo}
          id={item.id}
          onClick={onClick}
          isOpen={focusedKey === item.id}
          notes={item.notes}
        />
      ))}
    </AccordionSection>
  );
};

export default Accordion;
