import { flatten } from 'flat';

import EnglishLocale from 'app/locales/en.json';
import PolishLocale from 'app/locales/pl.json';
import SpanishLocale from 'app/locales/es.json';

type AppLocales = {
  [key: string]: any,
};

export const locales: AppLocales = {
  en: flatten(EnglishLocale),
  es: flatten(SpanishLocale),
  pl: flatten(PolishLocale),
};
