import React from 'react';
import styled, { useTheme } from 'styled-components';

import Typography from 'app/ui/components/atoms/typography';

const Wrapper = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  margin: ${({ theme }) => `0 0 ${theme.spacing.jumbo}`};

  & > :last-child {
    word-break: break-word;
  }
`;

type BreadcrumbProps = {
  content: string[];
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({ content }) => {
  const theme = useTheme();
  return (
    <Wrapper>
      {content
        .map(
          (string: string, index: number) => (
            <Typography key={`bcb-${index}`} variant="body2" color={theme.colors.lightGray}>
              {string}
            </Typography>
          ) as any,
        )
        .reduce((prev: React.FC<any>, curr: React.FC<any>, index: number) => [
          prev,
          <Typography key={`reduced-bcb-${index}`} variant="body2" color={theme.colors.lightGray}>
            &gt;
          </Typography>,
          curr,
        ])}
    </Wrapper>
  );
};

export default Breadcrumb;
