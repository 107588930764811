export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  ConceptId: { input: any; output: any; }
  DocumentId: { input: any; output: any; }
  ExerciseId: { input: any; output: any; }
  ISODateTime: { input: any; output: any; }
  LessonId: { input: any; output: any; }
  LessonScheduleId: { input: any; output: any; }
  LessonSectionId: { input: any; output: any; }
  LinkId: { input: any; output: any; }
  ResourceId: { input: any; output: any; }
  ResourceSectionId: { input: any; output: any; }
  SectionExerciseGroupId: { input: any; output: any; }
  SectionExerciseId: { input: any; output: any; }
  URL: { input: any; output: any; }
};

export type AssignUserToGymInput = {
  gym: GymInput;
  user: UserInput;
};

export type CompleteLessonByIdInput = {
  user: UserInput;
};

export type Concept = {
  __typename?: 'Concept';
  id: Scalars['ConceptId']['output'];
  name: Scalars['String']['output'];
};

export type ConceptInput = {
  id: Scalars['ConceptId']['input'];
};

export enum CountryCode {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW'
}

export type CreateConceptInput = {
  name: Scalars['String']['input'];
};

export type CreateDocumentInput = {
  keyName?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['URL']['input']>;
};

export type CreateEquipmentInput = {
  name: Scalars['String']['input'];
};

export type CreateExerciseInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  description: Scalars['String']['input'];
  duration?: InputMaybe<Scalars['Float']['input']>;
  equipment?: InputMaybe<EquipmentInput>;
  name: Scalars['String']['input'];
  video?: InputMaybe<VideoInput>;
};

export type CreateGymInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  country?: InputMaybe<CountryCode>;
  equipment?: InputMaybe<Array<EquipmentInput>>;
  gymType?: InputMaybe<GymTypeInput>;
  name: Scalars['String']['input'];
  preferredLanguage?: InputMaybe<LanguageInput>;
};

export type CreateGymTypeInput = {
  name: Scalars['String']['input'];
};

export type CreateImageInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  keyName?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['URL']['input']>;
};

export type CreateLanguageInput = {
  code: Scalars['String']['input'];
  image?: InputMaybe<ImageInput>;
  name: Scalars['String']['input'];
};

export type CreateLessonInput = {
  concept: ConceptInput;
  description: Scalars['String']['input'];
  equipment?: InputMaybe<Array<EquipmentInput>>;
  image?: InputMaybe<ImageInput>;
  intensity: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  publishedAt?: InputMaybe<Scalars['ISODateTime']['input']>;
  trainers?: InputMaybe<Array<UserInput>>;
};

export type CreateLessonScheduleInput = {
  lesson: LessonInput;
  scheduledAt: Scalars['ISODateTime']['input'];
};

export type CreateLessonSectionInput = {
  description: Scalars['String']['input'];
  duration?: InputMaybe<Scalars['Float']['input']>;
  format?: InputMaybe<LessonSectionFormat>;
  isMain?: InputMaybe<Scalars['Boolean']['input']>;
  isRotational?: InputMaybe<Scalars['Boolean']['input']>;
  lesson?: InputMaybe<LessonInput>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  repeatTimes?: InputMaybe<Scalars['Int']['input']>;
  video?: InputMaybe<VideoInput>;
};

export type CreateLinkInput = {
  url: Scalars['URL']['input'];
};

export type CreateResourceInput = {
  content: Scalars['String']['input'];
  image: ImageInput;
  title: Scalars['String']['input'];
};

export type CreateResourceSectionInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<Array<DocumentInput>>;
  images?: InputMaybe<Array<ImageInput>>;
  links?: InputMaybe<Array<LinkInput>>;
  resource?: InputMaybe<ResourceInput>;
  title: Scalars['String']['input'];
  videos?: InputMaybe<Array<VideoInput>>;
};

export type CreateSectionExerciseGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  lessonSection?: InputMaybe<LessonSectionInput>;
  name: Scalars['String']['input'];
  repeatTimes?: InputMaybe<Scalars['Int']['input']>;
  showTitle?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateSectionExerciseInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  distance?: InputMaybe<Scalars['Float']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  exercise: ExerciseInput;
  group?: InputMaybe<SectionExerciseGroupInput>;
  repetitions?: InputMaybe<Scalars['Int']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateUserInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  gym: GymInput;
  lastName: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  preferredLanguage?: InputMaybe<LanguageInput>;
  profileImage?: InputMaybe<ImageInput>;
  type: UserType;
};

export type CreateVideoInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  keyName?: InputMaybe<Scalars['String']['input']>;
  thumbnail?: InputMaybe<ImageInput>;
  url?: InputMaybe<Scalars['URL']['input']>;
};

export type DateRange = {
  from?: InputMaybe<Scalars['ISODateTime']['input']>;
  to?: InputMaybe<Scalars['ISODateTime']['input']>;
};

export type Document = {
  __typename?: 'Document';
  id: Scalars['DocumentId']['output'];
  keyName?: Maybe<Scalars['String']['output']>;
  signedUrl?: Maybe<Scalars['URL']['output']>;
  uploadDetails?: Maybe<MediaUploadDetails>;
};


export type DocumentUploadDetailsArgs = {
  extension: DocumentFileExtensions;
};

export enum DocumentFileExtensions {
  PDF = 'PDF'
}

export type DocumentInput = {
  id: Scalars['DocumentId']['input'];
};

export type DurationRange = {
  max?: InputMaybe<Scalars['Float']['input']>;
  min?: InputMaybe<Scalars['Float']['input']>;
};

export type Equipment = {
  __typename?: 'Equipment';
  id: Scalars['Int']['output'];
  lessons?: Maybe<Array<Lesson>>;
  name: Scalars['String']['output'];
};

export type EquipmentInput = {
  id: Scalars['Int']['input'];
};

export type Exercise = {
  __typename?: 'Exercise';
  active: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  duration?: Maybe<Scalars['Float']['output']>;
  equipment?: Maybe<Equipment>;
  id: Scalars['ExerciseId']['output'];
  name: Scalars['String']['output'];
  video?: Maybe<Video>;
};

export type ExerciseCondition = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ExerciseInput = {
  id: Scalars['ExerciseId']['input'];
};

export enum FileType {
  DOCUMENT = 'DOCUMENT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO'
}

export type Gym = {
  __typename?: 'Gym';
  /**  Whether the gym (and all its PTs) is active or disabled  */
  active: Scalars['Boolean']['output'];
  country?: Maybe<CountryCode>;
  equipment?: Maybe<Array<Equipment>>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  preferredLanguage?: Maybe<Language>;
  type?: Maybe<GymType>;
  users?: Maybe<Array<User>>;
};

export type GymCondition = {
  gymTypeId?: InputMaybe<Scalars['Int']['input']>;
};

export type GymInput = {
  id: Scalars['Int']['input'];
};

export type GymType = {
  __typename?: 'GymType';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type GymTypeInput = {
  id: Scalars['Int']['input'];
};

export type Image = {
  __typename?: 'Image';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  keyName?: Maybe<Scalars['String']['output']>;
  signedUrl?: Maybe<Scalars['URL']['output']>;
  uploadDetails?: Maybe<MediaUploadDetails>;
  url?: Maybe<Scalars['URL']['output']>;
};


export type ImageUploadDetailsArgs = {
  extension: ImageFileExtensions;
};

export enum ImageFileExtensions {
  JPEG = 'JPEG',
  JPG = 'JPG',
  PNG = 'PNG'
}

export type ImageInput = {
  id: Scalars['Int']['input'];
};

export type Language = {
  __typename?: 'Language';
  code: Scalars['String']['output'];
  image?: Maybe<Image>;
  name: Scalars['String']['output'];
};

export type LanguageInput = {
  code: Scalars['String']['input'];
};

export type Lesson = {
  __typename?: 'Lesson';
  concept: Concept;
  description: Scalars['String']['output'];
  duration: Scalars['Float']['output'];
  equipment?: Maybe<Array<Equipment>>;
  id: Scalars['LessonId']['output'];
  image?: Maybe<Image>;
  intensity: Scalars['Int']['output'];
  isCompleted: Scalars['Boolean']['output'];
  isSaved: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  publishedAt?: Maybe<Scalars['ISODateTime']['output']>;
  schedules?: Maybe<Array<LessonSchedule>>;
  sections?: Maybe<Array<LessonSection>>;
  status: LessonStatus;
  trainers?: Maybe<Array<User>>;
};

export type LessonCondition = {
  concept?: InputMaybe<ConceptInput>;
  durationRange?: InputMaybe<DurationRange>;
  equipment?: InputMaybe<Array<EquipmentInput>>;
  intensity?: InputMaybe<Scalars['Int']['input']>;
  publishedAt?: InputMaybe<Scalars['ISODateTime']['input']>;
  status?: InputMaybe<Array<LessonStatus>>;
};

export type LessonInput = {
  id: Scalars['LessonId']['input'];
};

export type LessonSchedule = {
  __typename?: 'LessonSchedule';
  id: Scalars['LessonScheduleId']['output'];
  lesson?: Maybe<Lesson>;
  scheduledAt: Scalars['ISODateTime']['output'];
};

export type LessonScheduleCondition = {
  dateRange?: InputMaybe<DateRange>;
  scheduledAt?: InputMaybe<Scalars['ISODateTime']['input']>;
};

export type LessonScheduleInput = {
  id: Scalars['Int']['input'];
};

export type LessonSection = {
  __typename?: 'LessonSection';
  description: Scalars['String']['output'];
  duration: Scalars['Float']['output'];
  format: LessonSectionFormat;
  id: Scalars['LessonSectionId']['output'];
  isMain: Scalars['Boolean']['output'];
  isRotational: Scalars['Boolean']['output'];
  lesson?: Maybe<Lesson>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  repeatTimes?: Maybe<Scalars['Int']['output']>;
  sectionExerciseGroups?: Maybe<Array<SectionExerciseGroup>>;
  video?: Maybe<Video>;
};

export enum LessonSectionFormat {
  AMRAP = 'AMRAP',
  CHIPPER = 'CHIPPER',
  COOLDOWN = 'COOLDOWN',
  COUPLET = 'COUPLET',
  DOUBLE_TABATA = 'DOUBLE_TABATA',
  INTERVALS = 'INTERVALS',
  INTRO = 'INTRO',
  I_GO_YOU_GO = 'I_GO_YOU_GO',
  PARTNER_CHIPPER = 'PARTNER_CHIPPER',
  PARTNER_WORKOUT = 'PARTNER_WORKOUT',
  TABATA = 'TABATA',
  TO2M = 'TO2M',
  TO3M = 'TO3M',
  TOTM = 'TOTM',
  TRIPLET = 'TRIPLET'
}

export type LessonSectionInput = {
  id: Scalars['LessonSectionId']['input'];
};

export enum LessonStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  SCHEDULED = 'SCHEDULED',
  TESTING = 'TESTING'
}

export type Link = {
  __typename?: 'Link';
  id: Scalars['LinkId']['output'];
  url: Scalars['URL']['output'];
};

export type LinkInput = {
  id: Scalars['LinkId']['input'];
};

export type MediaUploadDetails = {
  __typename?: 'MediaUploadDetails';
  keyName: Scalars['String']['output'];
  url: Scalars['URL']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']['output']>;
  assignUserToGym: User;
  completeLessonById: Lesson;
  createConcept: Concept;
  createDocument: Document;
  createEquipment: Equipment;
  createExercise: Exercise;
  createGym: Gym;
  createGymType: GymType;
  createImage: Image;
  createLanguage: Language;
  createLesson: Lesson;
  createLessonSchedule: LessonSchedule;
  createLessonSection: LessonSection;
  createLink: Link;
  createResource: Resource;
  createResourceSection: ResourceSection;
  createSectionExercise: SectionExercise;
  createSectionExerciseGroup: SectionExerciseGroup;
  createUser: User;
  createVideo: Video;
  deleteDocumentById: Scalars['DocumentId']['output'];
  deleteImageById: Scalars['Int']['output'];
  deleteLessonScheduleById: Scalars['LessonScheduleId']['output'];
  deleteLessonSectionById: Scalars['LessonSectionId']['output'];
  deleteLinkById: Scalars['LinkId']['output'];
  deleteResourceById: Scalars['ResourceId']['output'];
  deleteResourceSectionById: Scalars['ResourceSectionId']['output'];
  deleteSectionExerciseById: Scalars['SectionExerciseId']['output'];
  deleteSectionExerciseGroupById: Scalars['SectionExerciseGroupId']['output'];
  deleteUserById: Scalars['Int']['output'];
  deleteVideoById: Scalars['Int']['output'];
  patchConceptById: Concept;
  patchDocumentById: Document;
  patchEquipmentById: Equipment;
  patchExerciseById: Exercise;
  patchGymById: Gym;
  patchGymTypeById: GymType;
  patchImageById: Image;
  patchLanguageByCode: Language;
  patchLessonById: Lesson;
  patchLessonScheduleById: LessonSchedule;
  patchLessonSectionById: LessonSection;
  patchLinkById: Link;
  patchResourceById: Resource;
  patchResourceSectionById: ResourceSection;
  patchSectionExerciseById: SectionExercise;
  patchSectionExerciseGroupById: SectionExerciseGroup;
  patchUserById: User;
  patchVideoById: Video;
  saveLessonById: Lesson;
  uncompleteLessonById: Lesson;
  unsaveLessonById: Lesson;
};


export type MutationAssignUserToGymArgs = {
  input: AssignUserToGymInput;
};


export type MutationCompleteLessonByIdArgs = {
  id: Scalars['LessonId']['input'];
  input: CompleteLessonByIdInput;
};


export type MutationCreateConceptArgs = {
  input: CreateConceptInput;
};


export type MutationCreateDocumentArgs = {
  input: CreateDocumentInput;
};


export type MutationCreateEquipmentArgs = {
  input: CreateEquipmentInput;
};


export type MutationCreateExerciseArgs = {
  input: CreateExerciseInput;
};


export type MutationCreateGymArgs = {
  input: CreateGymInput;
};


export type MutationCreateGymTypeArgs = {
  input: CreateGymTypeInput;
};


export type MutationCreateImageArgs = {
  input: CreateImageInput;
};


export type MutationCreateLanguageArgs = {
  input: CreateLanguageInput;
};


export type MutationCreateLessonArgs = {
  input: CreateLessonInput;
};


export type MutationCreateLessonScheduleArgs = {
  input: CreateLessonScheduleInput;
};


export type MutationCreateLessonSectionArgs = {
  input: CreateLessonSectionInput;
};


export type MutationCreateLinkArgs = {
  input: CreateLinkInput;
};


export type MutationCreateResourceArgs = {
  input: CreateResourceInput;
};


export type MutationCreateResourceSectionArgs = {
  input: CreateResourceSectionInput;
};


export type MutationCreateSectionExerciseArgs = {
  input: CreateSectionExerciseInput;
};


export type MutationCreateSectionExerciseGroupArgs = {
  input: CreateSectionExerciseGroupInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateVideoArgs = {
  input: CreateVideoInput;
};


export type MutationDeleteDocumentByIdArgs = {
  id: Scalars['DocumentId']['input'];
};


export type MutationDeleteImageByIdArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteLessonScheduleByIdArgs = {
  id: Scalars['LessonScheduleId']['input'];
};


export type MutationDeleteLessonSectionByIdArgs = {
  id: Scalars['LessonSectionId']['input'];
};


export type MutationDeleteLinkByIdArgs = {
  id: Scalars['LinkId']['input'];
};


export type MutationDeleteResourceByIdArgs = {
  id: Scalars['ResourceId']['input'];
};


export type MutationDeleteResourceSectionByIdArgs = {
  id: Scalars['ResourceSectionId']['input'];
};


export type MutationDeleteSectionExerciseByIdArgs = {
  id: Scalars['SectionExerciseId']['input'];
};


export type MutationDeleteSectionExerciseGroupByIdArgs = {
  id: Scalars['SectionExerciseGroupId']['input'];
};


export type MutationDeleteUserByIdArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteVideoByIdArgs = {
  id: Scalars['Int']['input'];
};


export type MutationPatchConceptByIdArgs = {
  id: Scalars['ConceptId']['input'];
  input: PatchConceptByIdInput;
};


export type MutationPatchDocumentByIdArgs = {
  id: Scalars['DocumentId']['input'];
  input: PatchDocumentByIdInput;
};


export type MutationPatchEquipmentByIdArgs = {
  id: Scalars['Int']['input'];
  input: PatchEquipmentByIdInput;
};


export type MutationPatchExerciseByIdArgs = {
  id: Scalars['ExerciseId']['input'];
  input: PatchExerciseByIdInput;
};


export type MutationPatchGymByIdArgs = {
  id: Scalars['Int']['input'];
  input: PatchGymByIdInput;
};


export type MutationPatchGymTypeByIdArgs = {
  id: Scalars['Int']['input'];
  input: PatchGymTypeByIdInput;
};


export type MutationPatchImageByIdArgs = {
  id: Scalars['Int']['input'];
  input: PatchImageByIdInput;
};


export type MutationPatchLanguageByCodeArgs = {
  code: Scalars['String']['input'];
  input: PatchLanguageByCodeInput;
};


export type MutationPatchLessonByIdArgs = {
  id: Scalars['LessonId']['input'];
  input: PatchLessonByIdInput;
};


export type MutationPatchLessonScheduleByIdArgs = {
  id: Scalars['LessonScheduleId']['input'];
  input: PatchLessonScheduleByIdInput;
};


export type MutationPatchLessonSectionByIdArgs = {
  id: Scalars['LessonSectionId']['input'];
  input: PatchLessonSectionByIdInput;
};


export type MutationPatchLinkByIdArgs = {
  id: Scalars['LinkId']['input'];
  input: PatchLinkByIdInput;
};


export type MutationPatchResourceByIdArgs = {
  id: Scalars['ResourceId']['input'];
  input: PatchResourceByIdInput;
};


export type MutationPatchResourceSectionByIdArgs = {
  id: Scalars['ResourceSectionId']['input'];
  input: PatchResourceSectionByIdInput;
};


export type MutationPatchSectionExerciseByIdArgs = {
  id: Scalars['SectionExerciseId']['input'];
  input: PatchSectionExerciseByIdInput;
};


export type MutationPatchSectionExerciseGroupByIdArgs = {
  id: Scalars['SectionExerciseGroupId']['input'];
  input: PatchSectionExerciseGroupByIdInput;
};


export type MutationPatchUserByIdArgs = {
  id: Scalars['Int']['input'];
  input: PatchUserByIdInput;
};


export type MutationPatchVideoByIdArgs = {
  id: Scalars['Int']['input'];
  input: PatchVideoByIdInput;
};


export type MutationSaveLessonByIdArgs = {
  id: Scalars['LessonId']['input'];
  input: SaveLessonByIdInput;
};


export type MutationUncompleteLessonByIdArgs = {
  id: Scalars['LessonId']['input'];
  input: UncompleteLessonByIdInput;
};


export type MutationUnsaveLessonByIdArgs = {
  id: Scalars['LessonId']['input'];
  input: UnsaveLessonByIdInput;
};

export type PaginatedDocument = PaginatedResponse & {
  __typename?: 'PaginatedDocument';
  nodes?: Maybe<Array<Document>>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedImages = PaginatedResponse & {
  __typename?: 'PaginatedImages';
  nodes?: Maybe<Array<Image>>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedResponse = {
  totalCount: Scalars['Int']['output'];
};

export type PaginatedVideos = PaginatedResponse & {
  __typename?: 'PaginatedVideos';
  nodes?: Maybe<Array<Video>>;
  totalCount: Scalars['Int']['output'];
};

export type PatchConceptByIdInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PatchDocumentByIdInput = {
  keyName?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['URL']['input']>;
};

export type PatchEquipmentByIdInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PatchExerciseByIdInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  equipment?: InputMaybe<EquipmentInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<VideoInput>;
};

export type PatchGymByIdInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  country?: InputMaybe<CountryCode>;
  equipment?: InputMaybe<Array<EquipmentInput>>;
  gymType?: InputMaybe<GymTypeInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  preferredLanguage?: InputMaybe<LanguageInput>;
};

export type PatchGymTypeByIdInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PatchImageByIdInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  keyName?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['URL']['input']>;
};

export type PatchLanguageByCodeInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<ImageInput>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PatchLessonByIdInput = {
  concept?: InputMaybe<ConceptInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  equipment?: InputMaybe<Array<EquipmentInput>>;
  image?: InputMaybe<ImageInput>;
  intensity?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['ISODateTime']['input']>;
  status?: InputMaybe<LessonStatus>;
  trainers?: InputMaybe<Array<UserInput>>;
};

export type PatchLessonScheduleByIdInput = {
  lesson?: InputMaybe<LessonInput>;
  scheduledAt?: InputMaybe<Scalars['ISODateTime']['input']>;
};

export type PatchLessonSectionByIdInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  format?: InputMaybe<LessonSectionFormat>;
  isMain?: InputMaybe<Scalars['Boolean']['input']>;
  isRotational?: InputMaybe<Scalars['Boolean']['input']>;
  lesson?: InputMaybe<LessonInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  repeatTimes?: InputMaybe<Scalars['Int']['input']>;
  video?: InputMaybe<VideoInput>;
};

export type PatchLinkByIdInput = {
  url?: InputMaybe<Scalars['URL']['input']>;
};

export type PatchResourceByIdInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<ImageInput>;
  status?: InputMaybe<ResourceStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PatchResourceSectionByIdInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<Array<DocumentInput>>;
  images?: InputMaybe<Array<ImageInput>>;
  links?: InputMaybe<Array<LinkInput>>;
  resource?: InputMaybe<ResourceInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  videos?: InputMaybe<Array<VideoInput>>;
};

export type PatchSectionExerciseByIdInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  distance?: InputMaybe<Scalars['Float']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  exercise?: InputMaybe<ExerciseInput>;
  group?: InputMaybe<SectionExerciseGroupInput>;
  repetitions?: InputMaybe<Scalars['Int']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type PatchSectionExerciseGroupByIdInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  lessonSection?: InputMaybe<LessonSectionInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  repeatTimes?: InputMaybe<Scalars['Int']['input']>;
  showTitle?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PatchUserByIdInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  preferredLanguage?: InputMaybe<LanguageInput>;
  profileImage?: InputMaybe<ImageInput>;
  type?: InputMaybe<UserType>;
};

export type PatchVideoByIdInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  keyName?: InputMaybe<Scalars['String']['input']>;
  thumbnail?: InputMaybe<ImageInput>;
  url?: InputMaybe<Scalars['URL']['input']>;
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']['output']>;
  allConcepts?: Maybe<Array<Concept>>;
  allDocuments?: Maybe<PaginatedDocument>;
  allEquipment?: Maybe<Array<Equipment>>;
  allExercises?: Maybe<Array<Exercise>>;
  allGymTypes?: Maybe<Array<GymType>>;
  allGyms?: Maybe<Array<Gym>>;
  allImages?: Maybe<PaginatedImages>;
  allLanguages?: Maybe<Array<Language>>;
  allLessonSchedules?: Maybe<Array<LessonSchedule>>;
  allLessonSections?: Maybe<Array<LessonSection>>;
  allLessons?: Maybe<Array<Lesson>>;
  allLinks?: Maybe<Array<Link>>;
  allResourceSections?: Maybe<Array<ResourceSection>>;
  allResources?: Maybe<Array<Resource>>;
  allSectionExerciseGroups?: Maybe<Array<SectionExerciseGroup>>;
  allSectionExercises?: Maybe<Array<SectionExercise>>;
  allUsers?: Maybe<Array<User>>;
  allVideos?: Maybe<PaginatedVideos>;
  conceptById?: Maybe<Concept>;
  documentById?: Maybe<Document>;
  equipmentById?: Maybe<Equipment>;
  exerciseById?: Maybe<Exercise>;
  gymById?: Maybe<Gym>;
  gymTypeById?: Maybe<GymType>;
  imageById?: Maybe<Image>;
  languageByCode?: Maybe<Language>;
  lessonById?: Maybe<Lesson>;
  lessonScheduleById?: Maybe<LessonSchedule>;
  lessonSectionById?: Maybe<LessonSection>;
  linkById?: Maybe<Link>;
  resourceById?: Maybe<Resource>;
  resourceSectionById?: Maybe<ResourceSection>;
  sectionExerciseById?: Maybe<SectionExercise>;
  sectionExerciseGroupById?: Maybe<SectionExerciseGroup>;
  uploadFileDetails: MediaUploadDetails;
  userById?: Maybe<User>;
  videoById?: Maybe<Video>;
};


export type QueryAllConceptsArgs = {
  filters?: InputMaybe<SelectAllArgs>;
};


export type QueryAllDocumentsArgs = {
  filters?: InputMaybe<SelectAllArgs>;
};


export type QueryAllEquipmentArgs = {
  filters?: InputMaybe<SelectAllArgs>;
};


export type QueryAllExercisesArgs = {
  condition?: InputMaybe<ExerciseCondition>;
  filters?: InputMaybe<SelectAllArgs>;
};


export type QueryAllGymTypesArgs = {
  filters?: InputMaybe<SelectAllArgs>;
};


export type QueryAllGymsArgs = {
  condition?: InputMaybe<GymCondition>;
  filters?: InputMaybe<SelectAllArgs>;
};


export type QueryAllImagesArgs = {
  filters?: InputMaybe<SelectAllArgs>;
};


export type QueryAllLanguagesArgs = {
  filters?: InputMaybe<SelectAllArgs>;
};


export type QueryAllLessonSchedulesArgs = {
  condition?: InputMaybe<LessonScheduleCondition>;
  filters?: InputMaybe<SelectAllArgs>;
};


export type QueryAllLessonSectionsArgs = {
  filters?: InputMaybe<SelectAllArgs>;
};


export type QueryAllLessonsArgs = {
  condition?: InputMaybe<LessonCondition>;
  filters?: InputMaybe<SelectAllArgs>;
};


export type QueryAllLinksArgs = {
  filters?: InputMaybe<SelectAllArgs>;
};


export type QueryAllResourceSectionsArgs = {
  condition?: InputMaybe<ResourceSectionCondition>;
  filters?: InputMaybe<SelectAllArgs>;
};


export type QueryAllResourcesArgs = {
  condition?: InputMaybe<ResourceCondition>;
  filters?: InputMaybe<SelectAllArgs>;
};


export type QueryAllSectionExerciseGroupsArgs = {
  filters?: InputMaybe<SelectAllArgs>;
};


export type QueryAllSectionExercisesArgs = {
  filters?: InputMaybe<SelectAllArgs>;
};


export type QueryAllUsersArgs = {
  condition?: InputMaybe<UserCondition>;
  filters?: InputMaybe<SelectAllArgs>;
};


export type QueryAllVideosArgs = {
  filters?: InputMaybe<SelectAllArgs>;
};


export type QueryConceptByIdArgs = {
  id: Scalars['ConceptId']['input'];
};


export type QueryDocumentByIdArgs = {
  id: Scalars['DocumentId']['input'];
};


export type QueryEquipmentByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryExerciseByIdArgs = {
  id: Scalars['ExerciseId']['input'];
};


export type QueryGymByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGymTypeByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryImageByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryLanguageByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryLessonByIdArgs = {
  id: Scalars['LessonId']['input'];
};


export type QueryLessonScheduleByIdArgs = {
  id: Scalars['LessonScheduleId']['input'];
};


export type QueryLessonSectionByIdArgs = {
  id: Scalars['LessonSectionId']['input'];
};


export type QueryLinkByIdArgs = {
  id: Scalars['LinkId']['input'];
};


export type QueryResourceByIdArgs = {
  id: Scalars['ResourceId']['input'];
};


export type QueryResourceSectionByIdArgs = {
  id: Scalars['ResourceSectionId']['input'];
};


export type QuerySectionExerciseByIdArgs = {
  id: Scalars['SectionExerciseId']['input'];
};


export type QuerySectionExerciseGroupByIdArgs = {
  id: Scalars['SectionExerciseGroupId']['input'];
};


export type QueryUploadFileDetailsArgs = {
  input?: InputMaybe<UploadFileInput>;
};


export type QueryUserByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryVideoByIdArgs = {
  id: Scalars['Int']['input'];
};

export type Resource = {
  __typename?: 'Resource';
  content?: Maybe<Scalars['String']['output']>;
  id: Scalars['ResourceId']['output'];
  image?: Maybe<Image>;
  sections?: Maybe<Array<ResourceSection>>;
  status: ResourceStatus;
  title: Scalars['String']['output'];
};

export type ResourceCondition = {
  status?: InputMaybe<Array<ResourceStatus>>;
};

export type ResourceInput = {
  id: Scalars['ResourceId']['input'];
};

export type ResourceSection = {
  __typename?: 'ResourceSection';
  content?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Document>>;
  id: Scalars['ResourceSectionId']['output'];
  images?: Maybe<Array<Image>>;
  links?: Maybe<Array<Link>>;
  resource?: Maybe<Resource>;
  title: Scalars['String']['output'];
  videos?: Maybe<Array<Video>>;
};

export type ResourceSectionCondition = {
  resource?: InputMaybe<ResourceInput>;
};

export type ResourceSectionInput = {
  id: Scalars['ResourceSectionId']['input'];
};

export enum ResourceStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  TESTING = 'TESTING'
}

export type SaveLessonByIdInput = {
  user: UserInput;
};

export type SectionExercise = {
  __typename?: 'SectionExercise';
  description?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  exercise: Exercise;
  group?: Maybe<SectionExerciseGroup>;
  id: Scalars['SectionExerciseId']['output'];
  repetitions?: Maybe<Scalars['Int']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

export type SectionExerciseGroup = {
  __typename?: 'SectionExerciseGroup';
  description?: Maybe<Scalars['String']['output']>;
  duration: Scalars['Float']['output'];
  id: Scalars['SectionExerciseGroupId']['output'];
  lessonSection?: Maybe<LessonSection>;
  name: Scalars['String']['output'];
  repeatTimes?: Maybe<Scalars['Int']['output']>;
  sectionExercises?: Maybe<Array<SectionExercise>>;
  showTitle: Scalars['Boolean']['output'];
};

export type SectionExerciseGroupInput = {
  id: Scalars['SectionExerciseGroupId']['input'];
};

export type SectionExerciseInput = {
  id: Scalars['SectionExerciseId']['input'];
};

export type SelectAllArgs = {
  direction?: InputMaybe<SelectDirection>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

export enum SelectDirection {
  asc = 'asc',
  desc = 'desc'
}

export enum SupportedFileExtensions {
  JPEG = 'JPEG',
  JPG = 'JPG',
  MP4 = 'MP4',
  PDF = 'PDF',
  PNG = 'PNG'
}

export type UncompleteLessonByIdInput = {
  user: UserInput;
};

export type UnsaveLessonByIdInput = {
  user: UserInput;
};

export type UploadFileInput = {
  extension: SupportedFileExtensions;
  type: FileType;
};

export type User = {
  __typename?: 'User';
  /**  Whether the user's account is active or disabled  */
  active: Scalars['Boolean']['output'];
  completedLessons?: Maybe<Array<Lesson>>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  gym: Gym;
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  permissions: UserPermissions;
  preferredLanguage?: Maybe<Language>;
  profileImage?: Maybe<Image>;
  savedLessons?: Maybe<Array<Lesson>>;
  type: UserType;
};

export type UserCondition = {
  email?: InputMaybe<Scalars['String']['input']>;
};

export type UserInput = {
  id: Scalars['Int']['input'];
};

export type UserPermissions = {
  __typename?: 'UserPermissions';
  canManageGym: Scalars['Boolean']['output'];
};

export enum UserType {
  TRAINEE = 'TRAINEE',
  TRAINER = 'TRAINER'
}

export type Video = {
  __typename?: 'Video';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  keyName?: Maybe<Scalars['String']['output']>;
  signedUrl?: Maybe<Scalars['URL']['output']>;
  thumbnail?: Maybe<Image>;
  uploadDetails?: Maybe<MediaUploadDetails>;
  url?: Maybe<Scalars['URL']['output']>;
};


export type VideoUploadDetailsArgs = {
  extension: VideoFileExtensions;
};

export enum VideoFileExtensions {
  MP4 = 'MP4'
}

export type VideoInput = {
  id: Scalars['Int']['input'];
};

export type LessonOverviewDetailsFragmentFragment = { __typename?: 'Lesson', id: any, name: string, description: string, duration: number, intensity: number, isSaved: boolean, status: LessonStatus, isCompleted: boolean, image?: { __typename?: 'Image', id: number, url?: any | null, signedUrl?: any | null } | null, trainers?: Array<{ __typename?: 'User', id: number, firstName: string, lastName: string }> | null, concept: { __typename?: 'Concept', id: any, name: string }, equipment?: Array<{ __typename?: 'Equipment', id: number, name: string }> | null };

export type CheckOnBoardingQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type CheckOnBoardingQuery = { __typename?: 'Query', userById?: { __typename?: 'User', id: number, gym: { __typename?: 'Gym', id: number, type?: { __typename?: 'GymType', id: number } | null, equipment?: Array<{ __typename?: 'Equipment', id: number }> | null } } | null };

export type HomePageQueryVariables = Exact<{
  date: Scalars['ISODateTime']['input'];
  dateRange: DateRange;
  userId: Scalars['Int']['input'];
}>;


export type HomePageQuery = { __typename?: 'Query', wotd?: Array<{ __typename?: 'LessonSchedule', id: any, lesson?: { __typename?: 'Lesson', id: any, name: string, description: string, duration: number, intensity: number, isSaved: boolean, status: LessonStatus, isCompleted: boolean, image?: { __typename?: 'Image', id: number, url?: any | null, signedUrl?: any | null } | null, trainers?: Array<{ __typename?: 'User', id: number, firstName: string, lastName: string }> | null, concept: { __typename?: 'Concept', id: any, name: string }, equipment?: Array<{ __typename?: 'Equipment', id: number, name: string }> | null } | null }> | null, weeklyWorkouts?: Array<{ __typename?: 'LessonSchedule', id: any, scheduledAt: any, lesson?: { __typename?: 'Lesson', id: any, name: string, description: string, duration: number, intensity: number, isSaved: boolean, status: LessonStatus, isCompleted: boolean, image?: { __typename?: 'Image', id: number, url?: any | null, signedUrl?: any | null } | null, trainers?: Array<{ __typename?: 'User', id: number, firstName: string, lastName: string }> | null, concept: { __typename?: 'Concept', id: any, name: string }, equipment?: Array<{ __typename?: 'Equipment', id: number, name: string }> | null } | null }> | null, user?: { __typename?: 'User', id: number, preferredLanguage?: { __typename?: 'Language', code: string, name: string } | null } | null };

export type EquipmentsQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type EquipmentsQuery = { __typename?: 'Query', userById?: { __typename?: 'User', id: number, gym: { __typename?: 'Gym', id: number }, permissions: { __typename?: 'UserPermissions', canManageGym: boolean } } | null, allEquipment?: Array<{ __typename?: 'Equipment', id: number, name: string }> | null };

export type PatchGymEquipmentsMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  input: PatchGymByIdInput;
}>;


export type PatchGymEquipmentsMutation = { __typename?: 'Mutation', patchGymById: { __typename?: 'Gym', id: number, equipment?: Array<{ __typename?: 'Equipment', id: number }> | null } };

export type GymsQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GymsQuery = { __typename?: 'Query', userById?: { __typename?: 'User', id: number, gym: { __typename?: 'Gym', id: number }, permissions: { __typename?: 'UserPermissions', canManageGym: boolean } } | null, allGymTypes?: Array<{ __typename?: 'GymType', id: number, name: string }> | null };

export type PatchGymTypeByGymMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  input: PatchGymByIdInput;
}>;


export type PatchGymTypeByGymMutation = { __typename?: 'Mutation', patchGymById: { __typename?: 'Gym', id: number, type?: { __typename?: 'GymType', id: number } | null } };

export type PatchGymMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  input: PatchGymByIdInput;
}>;


export type PatchGymMutation = { __typename?: 'Mutation', patchGymById: { __typename?: 'Gym', id: number, type?: { __typename?: 'GymType', id: number, name: string } | null, equipment?: Array<{ __typename?: 'Equipment', id: number, name: string }> | null } };

export type PatchUserMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  input: PatchUserByIdInput;
}>;


export type PatchUserMutation = { __typename?: 'Mutation', patchUserById: { __typename?: 'User', id: number, email: string, firstName: string, lastName: string } };

export type ProfilePageQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type ProfilePageQuery = { __typename?: 'Query', userById?: { __typename?: 'User', id: number, firstName: string, lastName: string, email: string, type: UserType, gym: { __typename?: 'Gym', id: number, name: string, type?: { __typename?: 'GymType', id: number, name: string } | null, equipment?: Array<{ __typename?: 'Equipment', id: number, name: string }> | null }, permissions: { __typename?: 'UserPermissions', canManageGym: boolean }, savedLessons?: Array<{ __typename?: 'Lesson', id: any, name: string, description: string, duration: number, intensity: number, isSaved: boolean, status: LessonStatus, isCompleted: boolean, image?: { __typename?: 'Image', id: number, url?: any | null, signedUrl?: any | null } | null, trainers?: Array<{ __typename?: 'User', id: number, firstName: string, lastName: string }> | null, concept: { __typename?: 'Concept', id: any, name: string }, equipment?: Array<{ __typename?: 'Equipment', id: number, name: string }> | null }> | null, completedLessons?: Array<{ __typename?: 'Lesson', id: any, name: string, description: string, duration: number, intensity: number, isSaved: boolean, status: LessonStatus, isCompleted: boolean, image?: { __typename?: 'Image', id: number, url?: any | null, signedUrl?: any | null } | null, trainers?: Array<{ __typename?: 'User', id: number, firstName: string, lastName: string }> | null, concept: { __typename?: 'Concept', id: any, name: string }, equipment?: Array<{ __typename?: 'Equipment', id: number, name: string }> | null }> | null } | null, allEquipment?: Array<{ __typename?: 'Equipment', id: number, name: string }> | null, allGymTypes?: Array<{ __typename?: 'GymType', id: number, name: string }> | null };

export type ResourcePageQueryVariables = Exact<{
  id: Scalars['ResourceId']['input'];
}>;


export type ResourcePageQuery = { __typename?: 'Query', resourceById?: { __typename?: 'Resource', id: any, title: string, status: ResourceStatus, content?: string | null, image?: { __typename?: 'Image', url?: any | null, signedUrl?: any | null } | null, sections?: Array<{ __typename?: 'ResourceSection', id: any, title: string, content?: string | null, images?: Array<{ __typename?: 'Image', id: number, signedUrl?: any | null }> | null, videos?: Array<{ __typename?: 'Video', id: number, signedUrl?: any | null, thumbnail?: { __typename?: 'Image', signedUrl?: any | null } | null }> | null, documents?: Array<{ __typename?: 'Document', id: any, signedUrl?: any | null }> | null, links?: Array<{ __typename?: 'Link', id: any, url: any }> | null }> | null } | null };

export type ResourcesQueryVariables = Exact<{ [key: string]: never; }>;


export type ResourcesQuery = { __typename?: 'Query', allResources?: Array<{ __typename?: 'Resource', id: any, title: string, status: ResourceStatus, content?: string | null, image?: { __typename?: 'Image', signedUrl?: any | null } | null }> | null };

export type CompleteLessonByIdMutationVariables = Exact<{
  id: Scalars['LessonId']['input'];
  input: CompleteLessonByIdInput;
}>;


export type CompleteLessonByIdMutation = { __typename?: 'Mutation', completeLessonById: { __typename?: 'Lesson', id: any, isCompleted: boolean } };

export type UncompleteLessonByIdMutationVariables = Exact<{
  id: Scalars['LessonId']['input'];
  input: UncompleteLessonByIdInput;
}>;


export type UncompleteLessonByIdMutation = { __typename?: 'Mutation', uncompleteLessonById: { __typename?: 'Lesson', id: any, isCompleted: boolean } };

export type SaveLessonByIdMutationVariables = Exact<{
  id: Scalars['LessonId']['input'];
  input: SaveLessonByIdInput;
}>;


export type SaveLessonByIdMutation = { __typename?: 'Mutation', saveLessonById: { __typename?: 'Lesson', id: any, isSaved: boolean } };

export type UnsaveLessonByIdMutationVariables = Exact<{
  id: Scalars['LessonId']['input'];
  input: UnsaveLessonByIdInput;
}>;


export type UnsaveLessonByIdMutation = { __typename?: 'Mutation', unsaveLessonById: { __typename?: 'Lesson', id: any, isSaved: boolean } };

export type WorkoutPageQueryVariables = Exact<{
  id: Scalars['LessonId']['input'];
}>;


export type WorkoutPageQuery = { __typename?: 'Query', lessonById?: { __typename?: 'Lesson', id: any, name: string, description: string, duration: number, intensity: number, isSaved: boolean, status: LessonStatus, isCompleted: boolean, sections?: Array<{ __typename?: 'LessonSection', id: any, format: LessonSectionFormat, duration: number, description: string, notes?: string | null, name: string, isRotational: boolean, isMain: boolean, video?: { __typename?: 'Video', url?: any | null, signedUrl?: any | null, thumbnail?: { __typename?: 'Image', id: number, signedUrl?: any | null } | null } | null, sectionExerciseGroups?: Array<{ __typename?: 'SectionExerciseGroup', id: any, name: string, description?: string | null, duration: number, showTitle: boolean, sectionExercises?: Array<{ __typename?: 'SectionExercise', id: any, duration?: number | null, repetitions?: number | null, distance?: number | null, description?: string | null, exercise: { __typename?: 'Exercise', id: any, name: string, description: string, duration?: number | null, video?: { __typename?: 'Video', url?: any | null, signedUrl?: any | null, thumbnail?: { __typename?: 'Image', id: number, url?: any | null, signedUrl?: any | null } | null } | null } }> | null }> | null }> | null, image?: { __typename?: 'Image', id: number, url?: any | null, signedUrl?: any | null } | null, trainers?: Array<{ __typename?: 'User', id: number, firstName: string, lastName: string }> | null, concept: { __typename?: 'Concept', id: any, name: string }, equipment?: Array<{ __typename?: 'Equipment', id: number, name: string }> | null } | null };

export type WorkoutsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type WorkoutsPageQuery = { __typename?: 'Query', lessons?: Array<{ __typename?: 'Lesson', id: any, name: string, description: string, duration: number, intensity: number, isSaved: boolean, status: LessonStatus, isCompleted: boolean, image?: { __typename?: 'Image', id: number, url?: any | null, signedUrl?: any | null } | null, trainers?: Array<{ __typename?: 'User', id: number, firstName: string, lastName: string }> | null, concept: { __typename?: 'Concept', id: any, name: string }, equipment?: Array<{ __typename?: 'Equipment', id: number, name: string }> | null }> | null, allConcepts?: Array<{ __typename?: 'Concept', id: any, name: string }> | null };

export type SaveLessonByIdClassCardMutationVariables = Exact<{
  id: Scalars['LessonId']['input'];
  input: SaveLessonByIdInput;
}>;


export type SaveLessonByIdClassCardMutation = { __typename?: 'Mutation', saveLessonById: { __typename?: 'Lesson', id: any, isSaved: boolean } };

export type UnsaveLessonByIdClassCardMutationVariables = Exact<{
  id: Scalars['LessonId']['input'];
  input: UnsaveLessonByIdInput;
}>;


export type UnsaveLessonByIdClassCardMutation = { __typename?: 'Mutation', unsaveLessonById: { __typename?: 'Lesson', id: any, isSaved: boolean } };

export type LanguagePickerWrapperQueryVariables = Exact<{ [key: string]: never; }>;


export type LanguagePickerWrapperQuery = { __typename?: 'Query', allLanguages?: Array<{ __typename?: 'Language', code: string, name: string }> | null };

export type UpdatePreferredLanguageMutationVariables = Exact<{
  userId: Scalars['Int']['input'];
  code: Scalars['String']['input'];
}>;


export type UpdatePreferredLanguageMutation = { __typename?: 'Mutation', patchUserById: { __typename?: 'User', id: number, preferredLanguage?: { __typename?: 'Language', code: string } | null } };

export type WorkoutListControllerQueryVariables = Exact<{ [key: string]: never; }>;


export type WorkoutListControllerQuery = { __typename?: 'Query', allEquipment?: Array<{ __typename?: 'Equipment', id: number, name: string }> | null, allConcepts?: Array<{ __typename?: 'Concept', id: any, name: string }> | null };

export type WorkoutListWrapperQueryVariables = Exact<{
  lessonsCondition: LessonCondition;
}>;


export type WorkoutListWrapperQuery = { __typename?: 'Query', lessons?: Array<{ __typename?: 'Lesson', id: any, name: string, description: string, duration: number, intensity: number, isSaved: boolean, status: LessonStatus, isCompleted: boolean, image?: { __typename?: 'Image', id: number, url?: any | null, signedUrl?: any | null } | null, trainers?: Array<{ __typename?: 'User', id: number, firstName: string, lastName: string }> | null, concept: { __typename?: 'Concept', id: any, name: string }, equipment?: Array<{ __typename?: 'Equipment', id: number, name: string }> | null }> | null, allConcepts?: Array<{ __typename?: 'Concept', id: any, name: string }> | null };


      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "PaginatedResponse": [
      "PaginatedDocument",
      "PaginatedImages",
      "PaginatedVideos"
    ]
  }
};
      export default result;
    