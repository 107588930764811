import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Signin from 'app/pages/signin';
import ForgotPassword from 'app/pages/forgot-password';
import SetPassword from 'app/pages/set-password';
import Profile from 'app/pages/profile';
import Faq from 'app/pages/faq';
import Workouts from 'app/pages/workouts';
import Workout from 'app/pages/workout';
import Home from 'app/pages/home';
import Resources from 'app/pages/resources';
import Resource from 'app/pages/resource';
import OnboardingGym from 'app/pages/onboarding-gym';
import OnboardingEquipment from 'app/pages/onboarding-equipment';

import { ProtectedRoute } from 'app/routes/protected-route';
import { SigninRoute } from 'app/routes/sign-in-route';
import { routes } from 'app/routes/constants';

export function AppRoutes() {
  return (
    <Routes>
      <Route element={<SigninRoute />}>
        <Route path={routes.SIGNIN} element={<Signin />} />
        <Route path={routes.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={routes.SET_PASSWORD} element={<SetPassword />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<Home />} />
        <Route path={routes.PROFILE} element={<Profile />} />
        <Route path={routes.FAQ} element={<Faq />} />
        <Route path={routes.WORKOUTS} element={<Workouts />} />
        <Route path={routes.WORKOUT} element={<Workout />} />
        <Route path={routes.ONBOARDING_GYM_TYPE} element={<OnboardingGym />} />
        <Route path={routes.ONBOARDING_GYM_EQUIPMENT} element={<OnboardingEquipment />} />
        <Route path={routes.RESOURCES} element={<Resources />} />
        <Route path={routes.RESOURCE} element={<Resource />} />
      </Route>
    </Routes>
  );
}
