export const routes = {
  SIGNIN: '/sign-in',
  FORGOT_PASSWORD: '/forgot-password',
  SET_PASSWORD: '/set-password',
  PROFILE: '/profile',
  FAQ: '/faq',
  WORKOUTS: '/workouts',
  WORKOUT: '/workout/:id',
  ONBOARDING_GYM_TYPE: '/onboarding/gym-type',
  ONBOARDING_GYM_EQUIPMENT: '/onboarding/gym-equipment',
  HOME: '/',
  RESOURCES: '/resources',
  RESOURCE: '/resource/:id',
};
