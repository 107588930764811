import { Theme } from 'app/providers/theme';
import React from 'react';
import styled from 'styled-components';

declare module 'styled-components' {
  export interface DefaultTheme extends Theme {}
}

// Helper function for media queries
type MediaSize = 'medium' | 'large' | 'small';

const mediaQuery = (
  theme: Theme,
  size: MediaSize,
  styles: (theme: Theme) => string,
): ReturnType<Theme['media'][MediaSize]> => {
  return theme.media[size]((theme) => styles(theme));
};

const StyledH1 = styled.h1`
  ${({ theme, color }) => `
    font-family: ${theme.typography.poppins};
    font-weight: ${theme.typography.bold};
    color: ${color || theme.colors.white};
    font-size: ${theme.typography.jumbo};
    letter-spacing: ${theme.typography.lsMicro};
    margin: 0;

    ${mediaQuery(theme, 'medium', (theme) => `font-size: ${theme.typography.massive};`)}
    ${mediaQuery(theme, 'large', (theme) => `font-size: ${theme.typography.humongous};`)}
  `}
`;

const StyledH2 = styled.h2`
  ${({ theme, color }) => `
    font-family: ${theme.typography.poppins};
    font-weight: ${theme.typography.bold};
    font-size: ${theme.typography.xlarge};
    color: ${color || theme.colors.white};
    letter-spacing: ${theme.typography.lsXsmall};

    ${mediaQuery(theme, 'medium', (theme) => `font-size: ${theme.typography.xlarge};`)}
    ${mediaQuery(theme, 'large', (theme) => `font-size: ${theme.typography.massive};`)}
  `}
`;

const StyledH3 = styled.h3`
  ${({ theme, color }) => `
    font-family: ${theme.typography.poppins};
    font-weight: ${theme.typography.bold};
    font-size: ${theme.typography.large};
    color: ${color || theme.colors.white};
    letter-spacing: ${theme.typography.lsSmall};

    ${mediaQuery(theme, 'medium', (theme) => `font-size: ${theme.typography.large};`)}
    ${mediaQuery(theme, 'large', (theme) => `font-size: ${theme.typography.jumbo};`)}
  `}
`;

const StyledH4 = styled.h4`
  ${({ theme, color }) => `
    font-family: ${theme.typography.poppins};
    font-weight: ${theme.typography.bold};
    font-size: ${theme.typography.medium};
    color: ${color || theme.colors.white};
    letter-spacing: ${theme.typography.lsMedium};

    ${mediaQuery(theme, 'medium', (theme) => `font-size: ${theme.typography.medium};`)}
    ${mediaQuery(theme, 'large', (theme) => `font-size: ${theme.typography.xlarge};`)}
  `}
`;

const StyledH5 = styled.h5`
  ${({ theme, color }) => `
    font-family: ${theme.typography.poppins};
    font-weight: ${theme.typography.bold};
    font-size: ${theme.typography.small};
    color: ${color || theme.colors.white};
    letter-spacing: ${theme.typography.lsLarge};
    line-height: ${theme.typography.lineHeight};

    ${mediaQuery(theme, 'medium', (theme) => `font-size: ${theme.typography.small};`)}
    ${mediaQuery(theme, 'large', (theme) => `font-size: ${theme.typography.medium};`)}
  `}
`;

const StyledH6 = styled.h6`
  ${({ theme, color }) => `
    font-family: ${theme.typography.poppins};
    font-weight: ${theme.typography.bold};
    font-size: ${theme.typography.tiny};
    color: ${color || theme.colors.white};
    letter-spacing: ${theme.typography.lsMassive};
  `}
`;

const Body1 = styled.p`
  ${({ theme, color }) => `
    font-family: ${theme.typography.poppins};
    font-weight: ${theme.typography.light};
    font-size: ${theme.typography.small};
    color: ${color || theme.colors.white};
    letter-spacing: ${theme.typography.lsXlarge};
    line-height: ${theme.typography.lineHeight};

    ${mediaQuery(theme, 'medium', (theme) => `font-size: ${theme.typography.small};`)}
    ${mediaQuery(theme, 'large', (theme) => `font-size: ${theme.typography.medium};`)}

  `}
`;

const Body2 = styled.p`
  ${({ theme, color }) => `
    font-family: ${theme.typography.poppins};
    font-weight: ${theme.typography.light};
    font-size: ${theme.typography.xsmall};
    color: ${color || theme.colors.white};
    letter-spacing: ${theme.typography.lsJumbo};
    line-height: ${theme.typography.lineHeight};

    ${mediaQuery(theme, 'medium', (theme) => `font-size: ${theme.typography.xsmall};`)}
    ${mediaQuery(theme, 'large', (theme) => `font-size: ${theme.typography.small};`)}
  `}
`;

const Body3 = styled.p`
  ${({ theme, color }) => `
    font-family: ${theme.typography.poppins};
    font-weight: ${theme.typography.light};
    font-size: ${theme.typography.xsmall};
    color: ${color || theme.colors.white};
    letter-spacing: ${theme.typography.lsJumbo};
    line-height: ${theme.typography.lineHeight};
  `}
`;

const Caption = styled.span`
  ${({ theme, color }) => `
    font-family: ${theme.typography.poppins};
    font-weight: ${theme.typography.light};
    font-size: ${theme.typography.xsmall};
    color: ${color || theme.colors.white};
    letter-spacing: ${theme.typography.lsHumongous};
    line-height: ${theme.typography.lineHeight};

    ${mediaQuery(theme, 'medium', (theme) => `font-size: ${theme.typography.xsmall};`)}
    ${mediaQuery(theme, 'large', (theme) => `font-size: ${theme.typography.xsmall};`)}
  `}
`;

const Label = styled.label`
  ${({ theme, color }) => `
    font-family: ${theme.typography.poppins};
    font-weight: ${theme.typography.bold};
    font-size: ${theme.typography.xsmall};
    color: ${color || theme.colors.white};
    letter-spacing: ${theme.typography.lsJumbo};

    ${mediaQuery(theme, 'medium', (theme) => `font-size: ${theme.typography.small};`)}
    ${mediaQuery(theme, 'large', (theme) => `font-size: ${theme.typography.medium};`)}
  `}
`;

const Button = styled.span`
  ${({ theme, color }) => `
    font-family: ${theme.typography.poppins};
    font-weight: ${theme.typography.bold};
    font-size: ${theme.typography.small};
    color: ${color || theme.colors.white};

    ${mediaQuery(theme, 'medium', (theme) => `font-size: ${theme.typography.xsmall};`)}
    ${mediaQuery(theme, 'large', (theme) => `font-size: ${theme.typography.small};`)}
  `}
`;

interface TypographyProps {
  variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body1' | 'body2' | 'body3' | 'caption' | 'label' | 'button' ;
  color?: string;
  children?: React.ReactNode;
  htmlContent?: string;
  className?: string;
}

const Typography: React.FC<TypographyProps> = ({ variant, color, children, htmlContent, className }) => {
  const Component: React.ElementType = {
    h1: StyledH1,
    h2: StyledH2,
    h3: StyledH3,
    h4: StyledH4,
    h5: StyledH5,
    h6: StyledH6,
    body1: Body1,
    body2: Body2,
    body3: Body3,
    caption: Caption,
    label: Label,
    button: Button,
  }[variant];

  return htmlContent ? (
    <Component className={className} color={color} dangerouslySetInnerHTML={{ __html: htmlContent }} />
  ) : (
    <Component className={className} color={color}>
      {children}
    </Component>
  );
};

export default Typography;
