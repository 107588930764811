import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { useIntl } from 'react-intl';

import Button from 'app/ui/components/atoms/button';
import Typography from 'app/ui/components/atoms/typography';
import Loading from 'app/ui/components/molecules/loading';

import ConceptIcon from 'app/assets/concept-icon.svg';
import IntensityIcon from 'app/assets/intensity-icon.svg';
import ChevronRight from 'app/ui/components/atoms/icons/chevron-right';

export type WOTDProps = {
  lessonId: string,
  mainDetails: string,
  concept: string,
  intensity: string,
  srcSet: string,
  cardTitle: string,
  isLoading?: boolean,
};

type WotdCardProps = {
  srcSet: string,
  title: string,
  to: string,
}

const WOTDWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;

  ${({ theme: { spacing } }) => `
      gap: ${spacing.xlarge};
  `}

  ${({ theme: { media, spacing } }) => {
    return `
        ${media.medium(
          () => `
            flex-direction: row;
            gap: ${spacing.superhumongous}
          `,
        )}
        `;
  }}
`;

const WOTDText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ theme: { spacing } }) => `
      gap: ${spacing.jumbo};
  `}

  ${({ theme: { media } }) => {
    return `
        ${media.medium(
          () => `
            width: 50%
            `,
        )}
        `;
  }}

  button {
    width: fit-content;
  }

  h1 {
    text-transform: uppercase;
    line-height: 1em;
  }
`;

const WOTDCardWrapper = styled.div`
  width: 100%;

  ${({ theme: { media } }) => (`
      ${media.medium(() => `width: 50%;`)}
  `)}

  ${({ theme: { media } }) => (`
      ${media.medium(() => `flex: 4;`)}
  `)}
`;

const WOTDTitle = styled(Typography)`
  text-transform: uppercase;
`;

const WOTDConcept = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  ${({ theme: { spacing } }) => `
      gap: ${spacing.medium};
  `}

  label {
    text-transform: uppercase;
  }
`;

const Icon = styled.div`
  width: 1em;
  height: 1em;
  align-items: baseline;

  img {
    width: 100%;
    height: 100%;
  }
`;

const ChevronIcon = styled(ChevronRight)`
  width: 1rem;
  height: 1rem;
`;

const MobileButtonWrapper = styled.div`
  ${({ theme: { media } }) => (`
      ${media.medium(() => `display: none;`)}
  `)}
`;

const DesktopButton = styled(Button)`
  display: none;
  ${({ theme: { media } }) => {
    return `
        ${media.medium(
          () => `
            display: flex;
            flex-direction: row;
            align-items: center;
            `,
        )}
        `;
  }}
`;

const MobileButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MainContainer = styled.div<{ srcSet: string }>`
  aspect-ratio: calc(420 / 260);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
  background-image: url(${(props) => props.srcSet});
  background-size: cover;
  background-position: center;
  padding: ${({ theme: { spacing } }) => spacing.medium};
  border-radius: 0.5rem;
`;

const Title = styled(Typography)`
  position: relative;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.1;
`;

const WotdCard = ({
  srcSet,
  title,
  to,
}: WotdCardProps) => {
  return (
    <Link to={to}>
      <MainContainer srcSet={srcSet}>
        <></>
        <Title variant='h4'>{title}</Title>
      </MainContainer>
    </Link>
  );
};


const WOTD: React.FC<WOTDProps> = ({
  lessonId,
  mainDetails,
  concept,
  intensity,
  srcSet,
  cardTitle,
  isLoading,
}) => {
  const intl = useIntl();

  const theme = useTheme();
  const navigate = useNavigate();

  if (isLoading) {
    return <Loading />;
  }

  const wodHeaderText = intl.formatMessage({ id: 'home.header' });
  const wodBtnText = intl.formatMessage({ id: 'home.buttons.wod' });

  return (
    <WOTDWrapper>
      <WOTDText>
        <WOTDTitle variant="h2">{wodHeaderText}</WOTDTitle>
        <WOTDConcept>
          <Icon>
            <img src={ConceptIcon} alt="Concept Icon" />
          </Icon>
          <Typography variant="label">{concept}</Typography>
          <Icon>
            <img src={IntensityIcon} alt="Intensity Icon" />
          </Icon>
          <Typography variant="label">{intensity}</Typography>
        </WOTDConcept>
        <Typography variant="body2" color={theme.colors.paragraphGray}>
          {mainDetails}
        </Typography>
        <DesktopButton
          type="primary"
          label={wodBtnText}
          onClick={() => navigate(`/workout/${lessonId}`)}
          className="desk-button"
          icon={
            <ChevronIcon color={theme.colors.black} />
          }
        />
      </WOTDText>
      <WOTDCardWrapper>
        <WotdCard
          srcSet={srcSet}
          title={cardTitle}
          // TODO - navigate tidy
          to={`/workout/${lessonId}`}
        />
      </WOTDCardWrapper>
      <MobileButtonWrapper>
        <MobileButton
          type="primary"
          label={wodBtnText}
          onClick={() => navigate(`/workout/${lessonId}`)}
          className="desk-button"
          icon={
            <ChevronIcon color={theme.colors.black} />
          }
        />
      </MobileButtonWrapper>
    </WOTDWrapper>
  );
};

export default WOTD;
