import moment from 'moment';

import React, { useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import { useIntl } from 'react-intl';
import { useAppState, useDispatch } from 'state';

import WOTD from 'app/ui/components/molecules/wotd';
import Error from 'app/ui/components/molecules/error';

import GenericPage from 'app/ui/components/layouts/generic-page';
import WorkoutList from 'app/ui/components/molecules/workout-list';

import { LessonToWorkoutDetails, lessonToWorkout } from 'app/pages/workouts';

import { HomePage as HOME } from 'app/pages/home/home.gql';

import { setLanguage } from 'actions/language';

import {
  HomePageQuery,
  HomePageQueryVariables,
  Language,
  Lesson,
  UpdatePreferredLanguageMutation,
  UpdatePreferredLanguageMutationVariables,
} from 'app/types/graphql';

import { 
  UpdatePreferredLanguage as UPDATE_LANGUAGE,
} from 'app/ui/components/molecules/language-picker/update-language.gql';

export type WOTDDetails = {
  lessonId: string,
  mainDetails: string,
  concept: string,
  intensity: string,
  srcSet: string,
  cardTitle: string,
};

const lessonToWotd = (lesson: Lesson): WOTDDetails => ({
  lessonId: lesson.id,
  mainDetails: lesson.description,
  concept: lesson.concept.name,
  intensity: `RPE ${lesson.intensity}`,
  srcSet: lesson.image?.url || lesson.image?.signedUrl,
  cardTitle: lesson.name,
});

type HomePageProps = {
  wotd: WOTDDetails | null,
  workouts: LessonToWorkoutDetails[],
  isDataLoading: boolean,
};

export const HomePage: React.FC<HomePageProps> = ({
  wotd,
  workouts,
  isDataLoading,
}) => {
  const intl = useIntl();

  // Slightly magic, but this is the start of week 0
  const startDate = moment.utc('2024-06-10').startOf('day');
  const now = moment.utc();

  const weekNumber = Math.max(now.diff(startDate, 'week'), 1);

  const currentWeekStartDate = startDate.clone().add(weekNumber, 'weeks').startOf('week').add(1, 'days');
  const currentWeekEndDate = currentWeekStartDate.clone().add(6, 'days');

  const formattedStartDate = currentWeekStartDate.format('DD/MM');
  const formattedEndDate = currentWeekEndDate.format('DD/MM');

  const wodWeekText = intl.formatMessage({ id: 'home.copy.week' });

  return (
    <GenericPage>
      {wotd && <WOTD {...wotd} isLoading={isDataLoading} />}
      <WorkoutList
        workouts={workouts}
        title={`${wodWeekText} ${weekNumber} - ${formattedStartDate}-${formattedEndDate}`}
      />
    </GenericPage>
  );
};

export const Home: React.FC = () => {
  const dispatch = useDispatch();
  const userId = useAppState(({ auth }) => auth.userId) as number;
  const selectedLanguage = useAppState(({ language }) => language.preferredLanguage);

  const date = moment.utc().startOf('day').toISOString();

  const monday = moment.utc().startOf('isoWeek').startOf('day').toISOString();
  const sunday = moment.utc().endOf('isoWeek').endOf('day').toISOString();

  const [updatePreferredLanguage] = useMutation<
    UpdatePreferredLanguageMutation,
    UpdatePreferredLanguageMutationVariables
  >(UPDATE_LANGUAGE);

  const queryVariables: HomePageQueryVariables = {
    date,
    dateRange: { from: monday, to: sunday },
    userId,
  };

  const { data, loading, error } = useQuery<HomePageQuery, HomePageQueryVariables>(HOME, {
    variables: queryVariables,
  });

  useEffect(() => {
    if (selectedLanguage) {
      updatePreferredLanguage({ variables: { userId, code: selectedLanguage.code } });
    }
  }, []);

  useEffect(() => {
    if (!selectedLanguage && !loading) {
      const preferredLanguage = data?.user?.preferredLanguage as Language;

      dispatch(setLanguage(preferredLanguage));
    }
  }, [data, loading, selectedLanguage]);

  if (error) {
    return <Error />;
  }

  const wotd = data?.wotd?.[0] && data.wotd[0]?.lesson ? lessonToWotd(data.wotd[0]?.lesson as Lesson) : null;
  const workouts = data?.weeklyWorkouts
    ?.map(({ lesson }) => lesson && lessonToWorkout(lesson))
    ?.filter((lesson) => !!lesson) as LessonToWorkoutDetails[]
    || [];

  return (
    <HomePage
      wotd={wotd}
      workouts={workouts}
      isDataLoading={loading}
    />
  );
};

export default Home;
