import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useIntl } from 'react-intl';

import Typography from 'app/ui/components/atoms/typography';

type ExpandableTextProps = {
  text: string,
};

type ExpandableElementProp = {
  expanded: boolean,
};

const Container = styled.div<ExpandableElementProp>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

const TextWrapper = styled.div<ExpandableElementProp>`
  display: inline-block;
  max-width: calc(100% - 60px);
  white-space: ${({ expanded }) => (expanded ? 'normal' : 'nowrap')};
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  padding-right: ${({ theme }) => `${theme.spacing.massive}`};
`;

const ToggleButton = styled.span`
  color: ${({ theme }) => theme.colors.focoltone};
  cursor: pointer;
  white-space: nowrap;
`;

const ExpandableText = ({ text }: ExpandableTextProps) => {
  const theme = useTheme();
  const intl = useIntl();

  const [expanded, setExpanded] = useState(false);

  const moreLabel = intl.formatMessage({ id: 'accordions.labels.more' });
  const lessLabel = intl.formatMessage({ id: 'accordions.labels.less' });

  return (
    <Container expanded={expanded}>
      <TextWrapper expanded={expanded}>
        <Typography variant="caption" color={theme.colors.paragraphGray}>{text}</Typography>
      </TextWrapper>
      <ToggleButton onClick={() => setExpanded(!expanded)}>
        {expanded ? lessLabel : moreLabel}
      </ToggleButton>
    </Container>
  );
};

export default ExpandableText;
