import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';

import { WorkoutListController as FILTERS } from 'app/ui/components/molecules/workout-list-controller/filters.gql';
import { WorkoutListControllerQuery, Concept, Equipment } from 'app/types/graphql';

import { PillData, Pills } from 'app/ui/components/molecules/pill-field-set';
import WorkoutListWrapper from 'app/ui/components/molecules/workout-list-wrapper';

import {
  createAllPill,
  useFilterChangeHandler, 
  createPillsFromData,
  useMultipleFilterChangeHandler,
} from 'app/ui/components/molecules/workout-list-controller/utils';

export type DurationData = PillData & {
  min: number,
  max?: number,
};

export type WorkoutFilterData = {
  concepts: Concept[],
  equipment: Equipment[],
  intensity: number[],
  duration: DurationData['id'],
};

export const AVAILABLE_DURATION_DATA: DurationData[] = [
  { id: '15-min', name: '<15 mins', min: 0, max: 15 },
  { id: '30-min', name: '16-30 mins', min: 16, max: 30 },
  { id: '45-min', name: '31-45 mins', min: 31, max: 45 },
  { id: '60-min', name: '46-60 mins', min: 46, max: 60 },
  { id: '1000-min', name: '>60 mins', min: 60 },
];

type Props = {
  allowMoreFilters?: boolean,
};

const WorkoutListController: React.FC<Props> = ({ allowMoreFilters = false }) => {
  const intl = useIntl();

  const [selectedConcept, setSelectedConcept] = React.useState<string | null>('all-concept');
  const [selectedEquipmentPieces, setSelectedEquipmentPieces] = React.useState<string[]>([]);
  const [selectedIntensity, setSelectedIntensity] = React.useState<number | null>(null);
  const [selectedDuration, setSelectedDuration] = React.useState<string | null>(null);

  const [showMoreFiltersSidebar, setShowMoreFiltersSidebar] = React.useState<boolean>(false);

  const [equipmentFilters, setEquipmentFilters] = React.useState<Pills>([]);
  const [conceptFilters, setConceptFilters] = React.useState<Pills>([]);
  const [durationFilters, setDurationFilters] = React.useState<Pills>([]);

  const { data } = useQuery<WorkoutListControllerQuery>(FILTERS);

  const allPillText = intl.formatMessage({ id: 'filters.labels.all' });

  useEffect(() => {
    const allEquipmentPill = createAllPill({
      name: allPillText,
      elementName: 'equipment',
      selectedElements: selectedEquipmentPieces,
    });
    const equipmentPills = createPillsFromData({
      prependPills: [allEquipmentPill],
      data: data?.allEquipment as Equipment[] || [],
      selectedElements: selectedEquipmentPieces,
    });

    const allConceptsPill = createAllPill({
      name: allPillText,
      elementName: 'concept',
      selectedElements: selectedConcept ? [selectedConcept] : [],
    });
    const conceptsPills = createPillsFromData({
      prependPills: [allConceptsPill],
      data: data?.allConcepts as Concept[] || [],
      selectedElements: selectedConcept ? [selectedConcept] : [],
    });

    const allDurationsPill = createAllPill({
      name: allPillText,
      elementName: 'duration',
      selectedElements: selectedDuration ? [selectedDuration] : [],
    });
    const durationsPills = createPillsFromData({
      prependPills: [allDurationsPill],
      data: AVAILABLE_DURATION_DATA,
      selectedElements: selectedDuration ? [selectedDuration] : [],
    });

    setEquipmentFilters(equipmentPills);
    setConceptFilters(conceptsPills);
    setDurationFilters(durationsPills)
  }, [data?.allEquipment, data?.allConcepts]);

  const handleEquipmentChange = useMultipleFilterChangeHandler({
    setFilters: setEquipmentFilters,
    setSelected: setSelectedEquipmentPieces,
    allOptionName: 'all-equipment',
  });
  const handleConceptChange = useFilterChangeHandler(setConceptFilters, setSelectedConcept);
  const handleDurationChange = useFilterChangeHandler(setDurationFilters, setSelectedDuration);

  const resetFilters = () => {
    setSelectedIntensity(null);
    handleEquipmentChange('all-equipment');
    handleConceptChange('all-concept');
    handleDurationChange('all-duration');
  };

  return (
    <WorkoutListWrapper
      selectedFilters={{
        concept: selectedConcept,
        equipment: selectedEquipmentPieces,
        intensity: selectedIntensity,
        duration: selectedDuration,
      }}
      selectedFiltersHandlers={{
        concept: handleConceptChange,
        equipment: handleEquipmentChange,
        intensity: setSelectedIntensity,
        duration: handleDurationChange,
      }}
      filterPills={{
        concept: conceptFilters,
        equipment: equipmentFilters,
        duration: durationFilters,
      }}
      manageMoreFilters={{
        showButton: allowMoreFilters,
        showSidebar: showMoreFiltersSidebar,
        setSidebar: setShowMoreFiltersSidebar,
        reset: resetFilters,
      }}
    />
  );
};

export default WorkoutListController;
