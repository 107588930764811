import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useIntl } from 'react-intl';

import Typography from 'app/ui/components/atoms/typography';
import Button from 'app/ui/components/atoms/button';
import VideoPlayer from 'app/ui/components/molecules/video-component';

import ChevronRight from 'app/ui/components/atoms/icons/chevron-right';

type Video = {
  signedUrl?: string;
  thumbnail?: {
    signedUrl?: string;
  } | null;
};

type ResourceSectionDetailsProps = {
  title: string;
  mediaFirst: boolean;
  content?: string | null;
  imageUrl?: string;
  video?: Video | null;
  documentUrl?: string;
  linkUrl?: string;
};

const Wrapper = styled.div<{ mediaFirst: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 4rem 0 0;
  ${({ theme: { media }, mediaFirst }) =>
    `${media.medium(
      () => `
        margin: 8rem 0 0;
        flex-direction: ${mediaFirst ? 'row' : 'row-reverse'};
        gap: 5rem;
    `,
    )}`};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const TitleWrapper = styled.div`
  margin: 0 0 1.5rem 0;
  ${({ theme: { media } }) => `${media.medium(() => 'margin: 0 0 0.6rem')}`};
`;

const TextContentWrapper = styled.div`
  margin: 0 0 1.5rem 0;
  ${({ theme: { media } }) => `${media.medium(() => 'margin: 0.6rem 0')}`};
`;

const MediaWrapper = styled.div`
  flex: 1;
`;

const ButtonWrapper = styled.div`
  margin: 0 0 1.25rem;
  ${({ theme: { media } }) => `${media.medium(() => 'margin: 0.6rem 0;')}`};
`;

const MobileButtonWrapper = styled(ButtonWrapper)`
  margin: 1.25rem 0 1.25rem;
  ${({ theme: { media } }) => {
    return `
        ${media.medium(
          () => `
            display: none;
            `,
        )}
        `;
  }}
`;

const DesktopButtonWrapper = styled(ButtonWrapper)`
  display: none;
  ${({ theme: { media } }) => {
    return `
        ${media.medium(
          () => `
            display: block;
            `,
        )}
        `;
  }}
`;

const StyledImage = styled.img`
  width: 100%;
`;

const ChevronIcon = styled(ChevronRight)`
  padding-top: 0.25rem;
  width: 1rem;
  height: 1rem;
`;

const ResourceSectionDetails: React.FC<ResourceSectionDetailsProps> = ({
  title,
  content,
  imageUrl,
  video,
  documentUrl,
  linkUrl,
  mediaFirst,
}) => {
  const theme = useTheme();
  const intl = useIntl();

  const documentLabel = intl.formatMessage({ id: 'resource.buttons.document' });
  const folderLabel = intl.formatMessage({ id: 'resource.buttons.folder' });

  return (
    <Wrapper mediaFirst={mediaFirst}>
      <TextWrapper>
        <TitleWrapper>
          <Typography variant="h3">{title}</Typography>
        </TitleWrapper>
        <TextContentWrapper>
          <Typography variant="body1">{content}</Typography>
        </TextContentWrapper>
        {documentUrl && (
          <DesktopButtonWrapper>
            <Button
              label={documentLabel}
              type="primary"
              onClick={() => (window.location.href = documentUrl)}
              icon={<ChevronIcon color={theme.colors.black} />}
              fullWidthMob
            />
          </DesktopButtonWrapper>
        )}
        {linkUrl && (
          <DesktopButtonWrapper>
            <Button
              label={folderLabel}
              type="primary"
              onClick={() => (window.location.href = linkUrl)}
              icon={<ChevronIcon color={theme.colors.black} />}
              fullWidthMob
            />
          </DesktopButtonWrapper>
        )}
      </TextWrapper>
      <MediaWrapper>
        {video?.signedUrl ? (
          <VideoPlayer
            src={video.signedUrl}
            title=""
            duration=""
            showRewindAndForward
            thumbnail={video.thumbnail?.signedUrl}
          />
        ) : (
          <StyledImage src={imageUrl} />
        )}
      </MediaWrapper>
      {documentUrl && (
        <MobileButtonWrapper>
          <Button
            label={documentLabel}
            type="primary"
            onClick={() => (window.location.href = documentUrl)}
            icon={<ChevronIcon color={theme.colors.black} />}
            fullWidthMob
          />
        </MobileButtonWrapper>
      )}
      {linkUrl && (
        <MobileButtonWrapper>
          <Button
            label={folderLabel}
            type="primary"
            onClick={() => (window.location.href = linkUrl)}
            icon={<ChevronIcon color={theme.colors.black} />}
            fullWidthMob
          />
        </MobileButtonWrapper>
      )}
    </Wrapper>
  );
};

export default ResourceSectionDetails;
